import design from '../photos/design.svg';
import dev from '../photos/dev.svg';
import seo from '../photos/seo.svg';
import mobile from '../photos/mobile.svg';
import media from '../photos/media.svg';
import consulting from '../photos/consulting.svg';
import prestation from '../photos/sous-traitance.svg';
import use from "../photos/mobile/use.svg"
import augmentation from "../photos/mobile/Augmentation.svg"
import engagement from "../photos/mobile/Engagement.svg"
import notification from "../photos/mobile/notification.svg"
import revolution from "../photos/mobile/revolution.svg"
import expertise from "../photos/mobile/expertise.svg"
import reference from "../photos/mobile/reference.svg"
import budget from "../photos/mobile/budget.svg"
import portfolio from "../photos/mobile/portfolio.svg"
import comm from "../photos/mobile/communication.svg"
import fb from "../photos/cm/facebook.svg"
import tw from "../photos/cm/twitter.svg"
import sn from "../photos/cm/snapchat.svg"
import li from "../photos/cm/linkedin.svg"
import ins from "../photos/cm/instagram.svg"
import pin from "../photos/cm/pinterest.svg"
import yout from "../photos/cm/youtube.svg"
import ref from "../photos/cm/ref.svg"
import suivi from "../photos/cm/suivi.svg"
import relation from "../photos/cm/relation.svg"
import cm from "../photos/cm/cm.svg"
import pub from "../photos/cm/pub.svg"
import seocm from "../photos/cm/seo.svg"
import per from "../photos/cm/performance.svg"

export const services = [
    {
      title: "Design",
      link:"/fr/ui-ux",
      icon: design, 
      description: "Nos designers créent des visuels uniques et sur mesure pour vous démarquer de la concurrence. Un design percutant qui reflète vos valeurs et capte l'attention.",
    },
    {
      title: "Développement",
      icon: dev, 
      link:"/fr/nos-services",
      description: "Nous développons des sites performants et optimisés, offrant une expérience utilisateur fluide. Confiez-nous votre projet, nous le ferons évoluer.",
    },
    {
      title: "Mobile",
      link:"/fr/developpement-mobile",
      icon: mobile, 
      description: "Nous concevons des solutions mobiles intuitives et performantes, adaptées à tous les appareils, pour maximiser l'engagement de vos utilisateurs.",
    },
    {
      title: "SEO",
      icon: seo,
      link:"/fr/search-engine-optimization",
      description: "Nos services de référencement vous permettront d'améliorer votre visibilité en ligne.",
    },
    {
      title: "Consulting",
      link:"/fr/service-de-consultation",
      icon: consulting,
      description: "Bénéficiez de conseils stratégiques personnalisés pour optimiser votre performance. Nos experts vous accompagnent dans vos projets pour des résultats concrets et mesurables.",
    },
    {
      title: "Sous-traitance",
      link:"/fr/prestation-de-service",
      icon: prestation, 
      description: "Confiez vos tâches répétitives ou spécifiques à notre équipe experte. Gagnez en efficacité et concentrez-vous sur votre cœur de métier grâce à nos solutions sur-mesure adaptées à vos besoins.",
    },
    {
      title: "Social Media",
      link:"/fr/community-management",
      icon: media, 
      description: "Boostez votre présence en ligne avec une gestion professionnelle de vos réseaux sociaux. Stratégies de contenu, gestion des campagnes et analyses : nous maximisons votre engagement et visibilité.",
    },
  ];
  
export const servicesAgence = [
    {
      id: 1,
      title: 'Développement Web et Mobile sur mesure',
      description:
        'Notre équipe de développeurs et de designers vous accompagne tout au long de votre projet pour créer un site unique, moderne et performant. Que ce soit pour un site e-commerce, un blog, ou un site vitrine, nous avons les solutions adaptées à vos besoins.',
    },
    {
      id: 2,
      title: 'Référencement SEO',
      description:
        'Le SEO est essentiel pour assurer la visibilité de votre site sur Google et d’autres moteurs de recherche. Nos experts en référencement naturel vous aideront à grimper dans les classements et à générer du trafic qualifié.',
    },
    {
      id: 3,
      title: 'Marketing Digital et Community Management',
      description:
        'Notre équipe de développeurs et de designers vous accompagne tout au long de votre projet pour créer un site unique, moderne et performant. Que ce soit pour un site e-commerce, un blog, ou un site vitrine, nous avons les solutions adaptées à vos besoins.',
    },
    {
      id: 4,
      title: 'Accompagnement et maintenance',
      description:
        'Nous ne nous arrêtons pas à la livraison de votre site. Nous assurons également la maintenance et le support technique pour garantir la performance et la sécurité de vos projets à long terme.',
    },
  ];
  export const steps = [
    { id: 1, title: 'Consultation Initiale', description: 'Nous discutons de vos objectifs, de vos idées et de votre vision pour comprendre vos attentes.' },
    { id: 2, title: 'Analyse et Planification', description: 'Nous réalisons une analyse approfondie de votre marché et de vos concurrents, établissant un plan stratégique adapté à vos besoins.' },
    { id: 3, title: 'Conception de Wireframes', description: 'Nous créons des maquettes de votre site pour visualiser la structure et le flux des informations.' },
    { id: 4, title: 'Design UX/UI', description: 'Notre équipe de designers élabore une interface utilisateur attrayante et intuitive, en mettant l’accent sur l’expérience utilisateur.' },
    { id: 5, title: 'Développement Technique', description: 'Nous passons à la phase de développement, en utilisant les langages et technologies appropriées pour garantir des performances optimales.' },
    { id: 6, title: 'Tests et Validation', description: 'Chaque fonctionnalité est rigoureusement testée pour assurer son bon fonctionnement et son ergonomie avant le lancement.' },
    { id: 7, title: 'Lancement du Site', description: 'Nous procédons à la mise en ligne de votre site, en veillant à ce que tout soit parfaitement opérationnel.' },
    { id: 8, title: 'Suivi et Maintenance', description: 'Une fois le site lancé, nous restons à vos côtés pour assurer un suivi régulier et des mises à jour, garantissant ainsi la sécurité et la performance.' },
  ];

 export const questionsWeb = [
    {
      question: "Quel est le délai de création d'un site web ?",
      answer: "Chez Oxton Digital, un site web est généralement opérationnel en 4 à 12 semaines, selon sa complexité. Nous nous engageons à livrer rapidement sans compromettre la qualité. Contactez-nous pour un devis et démarrez votre projet dès aujourd'hui !",
    },
    {
      question: "Avez-vous des exemples de projets antérieurs ?",
      answer: "Oui, nous avons de nombreux exemples de projets réussis. Pour découvrir notre expertise et voir nos réalisations, consultez notre page portfolio ! De plus, nous sommes fiers d'afficher une note de 5 étoiles sur Google, avec plus de 43 avis, attestant de la satisfaction de nos clients.",
    },
    {
      question: "Quels types de sites internet développez-vous ?",
      answer: `
        Nous développons :
        <ul>
          <li>Sites vitrines pour présenter votre entreprise.</li>
          <li>Sites e-commerce pour vendre en ligne.</li>
          <li>Blogs pour partager vos passions.</li>
          <li>Applications web sur mesure pour des solutions personnalisées.</li>
          <li>Sites éducatifs pour l’apprentissage en ligne.</li>
        </ul>
      `,
    },
    {
      question: "Puis-je mettre à jour mon site moi-même après sa création ?",
      answer: "Oui, vous pouvez mettre à jour votre site vous-même après sa création. Nous utilisons des systèmes de gestion de contenu (CMS) faciles à utiliser, vous permettant de modifier du texte, des images et d’ajouter de nouveaux contenus sans compétences techniques.",
    },
    {
      question: "Comment assurez-vous la sécurité des sites que vous développez ?",
      answer: `
        Nous assurons la sécurité de votre site grâce à :
        <ul>
          <li>Mises à jour régulières des plateformes et plugins.</li>
          <li>Sauvegardes fréquentes de vos données.</li>
          <li>Certificats SSL pour sécuriser les connexions.</li>
          <li>Tests de sécurité pour détecter les vulnérabilités.</li>
        </ul>
      `,
    },
    {
      question: "Travaillez-vous avec des entreprises de toutes tailles ?",
      answer: "Oui, nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises. Chaque projet est traité avec la même attention et le même engagement, quel que soit votre budget ou vos besoins. Vous pouvez compter sur nous pour créer des solutions sur mesure qui répondent parfaitement à vos objectifs.",
    },
  ];
  export const questionsMobile = [
    {
      question: "Combien coûte le développement d'une application mobile ?",
      answer: "Le coût du développement varie en fonction de la complexité de l'application, des fonctionnalités souhaitées et de la plateforme (iOS, Android ou les deux). Chez Oxton Digital, nous travaillons avec vous pour établir un budget qui correspond à vos besoins et à vos objectifs.",
    },
    {
      question: "Combien de temps faut-il pour développer une application mobile ?",
      answer: "Le délai de développement dépend également de la complexité du projet. En général, cela peut prendre de quelques semaines à plusieurs mois. Nous vous fournissons un calendrier détaillé dès le début pour que vous sachiez à quoi vous attendre.",
    },
    {
      question: "Quelles plateformes devrais-je cibler pour mon application ?",
      answer: `Cela dépend de votre public cible. Nous vous aidons à analyser les préférences de vos utilisateurs et à choisir entre iOS, Android ou une solution multiplateforme, afin d'atteindre le maximum d'utilisateurs.`,
    },
    {
      question: "Puis-je mettre à jour mon site moi-même après sa création ?",
      answer: "Oui, vous pouvez mettre à jour votre site vous-même après sa création. Nous utilisons des systèmes de gestion de contenu (CMS) faciles à utiliser, vous permettant de modifier du texte, des images et d’ajouter de nouveaux contenus sans compétences techniques.",
    },
    {
      question: "Comment assurez-vous la qualité et la sécurité de l'application ?",
      answer: `Nous suivons des processus rigoureux de test et de validation tout au long du développement. De plus, nous intégrons des mesures de sécurité robustes pour protéger les données de vos utilisateurs.`,
    },
    {
      question: "Puis-je apporter des modifications pendant le développement ?",
      answer: "Oui, nous comprenons que des ajustements peuvent être nécessaires. Nous travaillons en collaboration avec vous pour intégrer vos retours tout au long du processus de développement.",
    },
    {
      question: "Offrez-vous un support après le lancement de l'application ? ",
      answer: "Absolument ! Nous proposons des services de maintenance et de support continu pour garantir que votre application reste performante et à jour.",
    },
    {
      question: "Comment mon application sera-t-elle mise à jour ? ",
      answer: "Nous vous aidons à planifier des mises à jour régulières pour intégrer de nouvelles fonctionnalités, corriger des bugs ou répondre aux retours des utilisateurs.",
    },
    {
      question: "Puis-je intégrer des fonctionnalités spécifiques, comme des paiements ou des notifications push ? ",
      answer: "Oui, nous sommes en mesure d’intégrer une variété de fonctionnalités selon vos besoins, y compris les systèmes de paiement, les notifications push et bien d'autres options personnalisées.",
    },
  ];

export const servicesMobiles = [
  {
    id: 1,
    image: use,
    title: "Utilisation croissante",
    description: "Environ 80 % des utilisateurs de smartphones préfèrent interagir avec des marques via des applications plutôt que par des sites web. Cela démontre l'importance d'être accessible à travers des canaux qui répondent aux attentes de vos clients.",
  },
  {
    id: 2,
    image: engagement,
    title: "Engagement renforcé",
    description: "Les applications mobiles génèrent 3 fois plus d'engagement que les sites web. Une application bien conçue peut fidéliser vos clients en leur offrant une expérience utilisateur fluide et intuitive.",
  },
  {
    id: 3,
    image: augmentation,
    title: "Augmentation des ventes",
    description: "Les entreprises qui disposent d'une application mobile constatent une augmentation moyenne de 20 à 30 % de leurs ventes. Cela s'explique par la facilité d'accès et l'expérience personnalisée qu'une application peut offrir.",
  },
  {
    id: 4,
    image: notification,
    title: "Notifications push",
    description: "Les applications permettent d'envoyer des notifications push, qui augmentent l'engagement client de 88 %. Ces messages peuvent informer vos clients de promotions, de nouveautés ou de contenus pertinents, les incitant à revenir régulièrement.",
  },
  {
    id: 5,
    image: revolution,
    title: "Révolution des secteurs",
    description: "Les applications mobiles transforment divers secteurs, de l'e-commerce à la santé, en passant par l'éducation. Les entreprises qui ne s'adaptent pas à cette tendance risquent de perdre des parts de marché face à des concurrents plus innovants.",
  },
];
export const cardsMobile = [
  {
    id: 1,
    icon: expertise,
    title: 'Une équipe commerciale pour vous conseiller',
    text: 'Notre équipe de consultants a une compréhension approfondie de la façon de créer de la valeur par la transformation numérique. Nous travaillons avec nos clients pour concevoir et mettre en œuvre des solutions commerciales personnalisées.',
  },
  {
    id: 2,
    icon: reference,
    title: 'Une équipe de chefs de projet pour le déploiement',
    text: 'Nos chefs de projet expérimentés travaillent avec vous pour assurer le déploiement réussi de votre projet numérique. Nous vous aidons à définir la portée et les exigences, identifier et évaluer les risques.',
  },
  {
    id: 3,
    icon: budget,
    title: 'Une équipe support pour vous assister',
    text: 'Nous proposons également une gamme d\'autres services de conseil, y compris des études de faisabilité, l\'amélioration des processus, et la gestion du changement.',
  },
  {
    id: 4,
    icon: portfolio,
    title: 'Une équipe support pour vous assister',
    text: 'Nous proposons également une gamme d\'autres services de conseil, y compris des études de faisabilité, l\'amélioration des processus, et la gestion du changement.',
  },
  {
    id: 5,
    icon: comm,
    title: 'Une équipe support pour vous assister',
    text: 'Nous proposons également une gamme d\'autres services de conseil, y compris des études de faisabilité, l\'amélioration des processus, et la gestion du changement.',
  }
];

export const actions = [
  {
    id: 1,
    icon: fb,
    text: "Animez votre Facebook avec des contenus créatifs et engageants."
  },
  {
    id: 2,
    icon: tw,
    text: "Affirmez votre expertise grâce à des tweets percutants sur Twitter."
  },
  {
    id: 3,
    icon: sn,
    text: "Faites vibrer votre communauté Snapchat avec des contenus authentiques."
  },
  {
    id: 4,
    icon: li,
    text: "Valorisez vos compétences et actualités professionnelles sur LinkedIn."
  },
  {
    id: 5,
    icon: ins,
    text: "Boostez votre image de marque avec un feed Instagram soigné et inspirant."
  },
  {
    id: 6,
    icon: pin,
    text: "Créez une vitrine visuelle captivante sur Pinterest."
  },
  {
    id: 7,
    icon: yout,
    text: "Racontez votre histoire en vidéo avec des campagnes impactantes sur YouTube."
  },
  {
    id: 8,
    icon: suivi,
    text: "Optimisez vos publicités grâce à un suivi stratégique et précis."
  },
  {
    id: 9,
    icon: ref,
    text: "Attirez un trafic qualifié avec une stratégie SEO performante."
  },
  {
    id: 10,
    icon: relation,
    text: "Collaborez avec des influenceurs pour élargir votre portée et fidéliser votre audience."
  }
];
export const servicesCm = [
  {
    id: 1,
    icon: cm,
    title: "Community Management",
    description: "",
    points: ["Fédérez une communauté passionnée qui grandit autour de votre marque.", "Imaginez des contenus qui captivent, inspirent et suscitent un réel engagement.", "Transformez chaque interaction en une opportunité de créer du lien et de fidéliser."]
  },
  {
    id: 2,
    icon: seocm,
    title: "Web Marketing (SEO, SEA)",
    description: "",
    points: ["Propulsez votre site en haut des résultats de recherche.", "Attirez les visiteurs qui comptent vraiment pour votre business.", "Optimisez vos campagnes pour un retour sur investissement maximal."]
  },
  {
    id: 3,
    icon: pub,
    title: "Publicité en ligne",
    description: "",
    points: ["Touchez vos futurs clients là où ils se trouvent, au bon moment.", "Créez des campagnes qui marquent les esprits et boostent vos ventes.", "Pilotez vos publicités avec précision pour des résultats concrets."]
  },
  {
    id: 4,
    icon: per,
    title: "Rapports de performance",
    description: "",
    points: ["Obtenez une vision claire de vos résultats et de vos opportunités.", "Transformez chaque donnée en une stratégie gagnante.", "Restez toujours un pas devant grâce à nos analyses sur-mesure."]
  }
];
