import React, { useEffect, useState } from "react";
import "./StatutServices.css";
import im1 from '../../../photos/Group 39074.svg'
import im2 from '../../../photos/maintenance 1.svg'
import im3 from '../../../photos/Sync.svg'
import { URL_Back } from "../Variables";

const StatutServices = () => {
  const [servers, setServers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const url = `${URL_Back}/monitoring/`;
  
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erreur: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const formattedData = Object.entries(data).map(([key, value]) => ({
          name: key,
          status: value,
        }));
        setServers(formattedData);
      })
      .catch(error => {
        setError(error.message);
      });
  }, []);
  
  console.log(servers)

  return (
    <div className="service-status container">
    <h2>Statut Des Services</h2>
    <p className="title-p p-statut">Informations En Temps Réel Sur Les Performances Et La Disponibilité Du Système.</p>
    <div className="status-cards">
      {servers.map((service, index) => (
        <div className="status-card" key={index}>
          <h3>{service.name}</h3>
          <span
            className={`status-label ${service.status === 'Operational' ? 'green' : 'blue'}`}>
            <img
              src={service.status === 'Operational' ? im1 : im2}
              alt="icon"
            />
            {service.status === 'Operational' ? 'Tous les systèmes opérationnels' : 'Événement(s) en cours'}
          </span>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default StatutServices;
