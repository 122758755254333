export default class Portfolio {
  constructor(id, title, content,slug,categoriesportfolio,defi_content,probleme_content,resultat_content,nos_actions_content,image_logo_content,image_caroussel_content,image_center_content,techo_choice_content, date, image, excerpt, p) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.slug = slug;
    this.categoriesportfolio = categoriesportfolio;
    this.defi_content = defi_content;
    this.probleme_content = probleme_content;
    this.resultat_content = resultat_content;
    this.nos_actions_content = nos_actions_content;
    this.image_logo_content = image_logo_content;
    this.image_caroussel_content = image_caroussel_content;
    this.image_center_content = image_center_content;
    this.techo_choice_content = techo_choice_content;
    this.date = date;
    this.image = image;
    this.excerpt = excerpt;
    this.p = p;
    
  }

  setTitle(title) {
    this.title.set(title);
  }
  getTitle(title) {
    return this.title.get(title);
  }
  setId(id) {
    this.id.set(id);
  }
  getId(id) {
    return this.age.get(id);
  }
  setContent(content) {
    this.content.set(content);
  }
  getContent(content) {
    return this.content.get(content);
  }
  setContent(slug) {
    this.slug.set(slug);
  }
  getContent(slug) {
    return this.slug.get(slug);
  }
  setCategories(categoriesportfolio) {
    this.categoriesportfolio.set(categoriesportfolio);
  }
  getCategories(categoriesportfolio) {
    return this.categoriesportfolio.get(categoriesportfolio);
  }
  setContentDefi(defi_content) {
    this.defi_content.set(defi_content);
  }
  getContentDefi(defi_content) {
    return this.defi_content.get(defi_content);
  }
  setContentProblems(probleme_content) {
    this.probleme_content.set(probleme_content);
  }
  getContentProblems(probleme_content) {
    return this.probleme_content.get(probleme_content);
  }
  setContentRes(resultat_content) {
    this.resultat_content.set(resultat_content);
  }
  getContentRes(resultat_content) {
    return this.resultat_content.get(resultat_content);
  }
  setContentActions(nos_actions_content) {
    this.nos_actions_content.set(nos_actions_content);
  }
  getContentActions(nos_actions_content) {
    return this.nos_actions_content.get(nos_actions_content);
  }
  setLogo(image_logo_content) {
    this.image_logo_content.set(image_logo_content);
  }
  getLogo(image_logo_content) {
    return this.image_logo_content.get(image_logo_content);
  }
  setCarousel(image_caroussel_content) {
    this.image_caroussel_content.set(image_caroussel_content);
  }
  getCarousel(image_caroussel_content) {
    return this.image_caroussel_content.get(image_caroussel_content);
  }
  setCenterImg(image_center_content) {
    this.image_center_content.set(image_center_content);
  }
  getCenterImg(image_center_content) {
    return this.image_center_content.get(image_center_content);
  }
  setTechno(techo_choice_content) {
    this.techo_choice_content.set(techo_choice_content);
  }
  getTechno(techo_choice_content) {
    return this.techo_choice_content.get(techo_choice_content);
  }
  setDate(date) {
    this.date.set(date);
  }
  getDate(date) {
    return this.date.get(date);
  }
  setImage(image) {
    this.image.set(image);
  }
  getImage(image) {
    return this.image.get(image);
  }
  setExcerpt(excerpt) {
    this.excerpt.set(excerpt);
  }
  getExcerpt(excerpt) {
    return this.excerpt.get(excerpt);
  }
  setP(p) {
    this.p.set(p);
  }
  getP(p) {
    return this.p.get(p);
  }
 
  
}