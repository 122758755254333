export default class Pages {
  constructor(id, title, content, parent, slug, description,metatitle, excerpt, date) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.parent = parent;
    this.slug = slug;
    this.description = description;
    this.metatitle = metatitle;
    this.excerpt = excerpt;
    this.date = date;
  }

  setId(id) {
      this.id.set(id);
  }
  getId(id) {
      return this.age.get(id);
  }

  setTitle(title) {
      this.title.set(title);
  }
  getTitle(title) {
      return this.title.get(title);
  }

  setContent(content) {
      this.content.set(content);
  }
  getContent(content) {
      return this.content.get(content);
  }

  setContent(parent) {
    this.parent.set(parent);
  }
  getContent(parent) {
      return this.parent.get(parent);
  }

  setContent(slug) {
    this.slug.set(slug);
  }
  getContent(slug) {
    return this.slug.get(slug);
  }

  setContent(description) {
    this.description.set(description);
  }
  getContent(description) {
    return this.description.get(description);
  }
  setContent(metatitle) {
    this.metatitle.set(metatitle);
  }
  getContent(metatitle) {
    return this.metatitle.get(metatitle);
  }
  setExcerpt(excerpt) {
      this.excerpt.set(excerpt);
  }
  getExcerptt(excerpt) {
      return this.excerpt.get(excerpt);
  }


  setDate(date) {
      this.date.set(date);
  }
  getDate(date) {
      return this.date.get(date);
  }

}