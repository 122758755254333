import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Breadcrumb, Title } from "../../../css/commun";
import trust from '../../../photos/agence/trust.svg'
import ethic from "../../../photos/agence/ethics.svg"
import reactivite from "../../../photos/agence/reactivite.svg"
import solution from "../../../photos/agence/solution.svg"
import surMesure from "../../../photos/agence/sur-mesure.svg"
import expertise from "../../../photos/agence/expertise.svg"
import gestion from "../../../photos/agence/gestion.svg"
import agility from "../../../photos/agence/agility.svg"
import satisfait from "../../../photos/satisfait.svg"
import projets from "../../../photos/projets.svg"
import garantie from "../../../photos/garantie.svg"
import img from '../../../photos/consulting/Group 38868.png'
import cl from "../../../photos/clients.svg"
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './Entreprise.css';
import { Link } from 'react-router-dom';
import MyButton from "../Services/MyButton";
import {servicesAgence} from "../../Data.js"

const Entreprise = (props) => {
  const title = props.location.pathname
  const staticTitles = {
    en: "Notre Agence",
    fr: "Notre Agence"
  };
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);
 
  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime());
    }
  };
   useEffect(() => {
      const gridItems = document.querySelectorAll(".grid-item-vision");
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("animate");
            }
         else {
               entry.target.classList.remove("animate");
             }
          });
        },
        { threshold: 0.2 }
      );
      gridItems.forEach((item) => observer.observe(item));
      return () => {
        observer.disconnect();
      };
    }, []); 

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description,element.yoast_head_json.title, element.date, element.excerpt.rendered)))


    console.log("page", page)


  return (
    <>
      <Helmet>
        <title>{page[0] !== undefined && page[0].metatitle}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container" >
        <Breadcrumb>
          <p>
            <Link className="retour" to={`/${langue}`}>{t('Home_link')}</Link> / <span>{titre}</span>
          </p>
        </Breadcrumb>
        <Title>
          <h1>{titre}</h1>

          <p className="title-p">Chez Oxton Digital, nous sommes spécialisés dans la création de solutions digitales sur mesure qui répondent parfaitement aux besoins uniques de votre entreprise. Avec plus de 15 ans d’expérience, notre agence allie expertise technique et créativité pour concevoir des sites web performants, attractifs et optimisés pour le référencement naturel (SEO). Que vous ayez besoin d’un site vitrine, d’une plateforme e-commerce ou d’un accompagnement en community management, nous avons les compétences pour transformer vos idées en résultats concrets</p>
        </Title>

        <Details className={langue === "ar" && "details-ar"}>

          <Description>
            <div className="respect-container">
              <div className="respect-icon">
                <img src={img} alt="Réactivité" />
              </div>
              <div className="respect-content">
                <h3>Pourquoi choisir Oxton Digital pour votre SEO ?</h3></div> </div>
            <div className="agence-cards">
              <div className="agence-card">
                <div className="agence-card-icon">
                  <img src={solution} alt="Icon 1" />
                </div>
                <h3 className="agence-card-title">Solutions sur mesure</h3>
                <p className="agence-card-text">
                  Nous ne proposons jamais de solutions toutes faites. Chaque projet est unique, et notre équipe s’assure de créer des sites web adaptés à vos objectifs commerciaux et à votre audience cible.
                </p>
              </div>

              <div className="agence-card cardc2-agence">
                <div className="agence-card-icon">
                  <img src={reactivite} alt="Icon 2" />
                </div>
                <h3 className="agence-card-title">Réactivité et expertise</h3>
                <p className="agence-card-text">
                  Nous suivons de près les évolutions du marché et les dernières tendances en matière de web et de marketing digital. Cela nous permet de vous proposer des solutions innovantes, tout en restant réactifs aux besoins spécifiques de votre projet.
                </p>
              </div>

              <div className="agence-card">
                <div className="agence-card-icon">
                  <img src={ethic} alt="Icon 3" />
                </div>
                <h3 className="agence-card-title">Éthique et qualité</h3>
                <p className="agence-card-text">
                  L’éthique professionnelle est au cœur de chacun de nos projets. Nous travaillons avec une attention particulière à la qualité de vie au travail et mettons un point d’honneur à garantir un niveau d’excellence dans chaque solution que nous livrons.
                </p>
              </div>
              <div className="agence-card cardc2-agence">
                <div className="agence-card-icon">
                  <img src={trust} alt="Icon 2" />
                </div>
                <h3 className="agence-card-title">Réputation et confiance</h3>
                <p className="agence-card-text">
                  Grâce à notre engagement et à notre capacité à livrer des projets de haute qualité, nous avons gagné la confiance de plus de 400 clients et mené à bien 625 projets avec une satisfaction de 100%.
                </p>
              </div>
            </div>

          </Description>

        </Details>
        <Pourquoi>
          <div className="respect-container">
            <div className="respect-content">
              <h3>Ce que nous pouvons vous apporter</h3></div> </div>
          <div className="service-list">
            {servicesAgence.map((service, index) => (
              <div
                key={service.id}
                className={`service-item-agence ${index === 0 ? 'service-item-one' : ''}`}>
                <div className="service-number-agence">{service.id}</div>
                <div className="service-content-agence">
                  <div className="service-agence-title"><h5>{service.title}</h5></div>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </Pourquoi>
        <div className="points-forts-container">
          <div className="respect-container">
            <div className="respect-icon">
              <img src={img} alt="Réactivité" />
            </div>
            <div className="respect-content">
              <h3>Quels sont nos points forts ?</h3></div> </div>
          <div className="points-forts">
            <div>
              <div className="d-flex forts">

                <div className="d-flex align-items-start gap-4 forts-img-title">
                  <img src={surMesure} className="" alt="sur-mesure" />
                  <div className="d-flex flex-column">
                    <h5>Sur mesure</h5>
                    <p className="title-p">Chaque projet est unique, et nous nous assurons que vos besoins spécifiques sont pris en compte à chaque étape.</p>
                  </div>
                </div>


                <div className="d-flex align-items-start gap-4 forts-img-title">
                  <img src={expertise} className="" alt="expertise" />
                  <div className="d-flex flex-column">
                    <h5>Expertise</h5>
                    <p className="title-p">Notre équipe d'experts en développement web, marketing digital et SEO garantit un résultat professionnel.</p>
                  </div>
                </div>
              </div>

              <div className="d-flex forts">

                <div className="d-flex align-items-start gap-4 forts-img-title">
                  <img src={gestion} className="" alt="gestion" />
                  <div className="d-flex flex-column">
                    <h5>Gestion de projet</h5>
                    <p className="title-p">Vous bénéficiez d’un accompagnement personnalisé avec un chef de projet dédié pour garantir le bon déroulement de vos projets dans les délais impartis</p>
                  </div>
                </div>

                <div className="d-flex align-items-start gap-4 forts-img-title">
                  <img src={agility} className="" alt="agility" />
                  <div className="d-flex flex-column">
                    <h5>Agilité</h5>
                    <p className="title-p">Grâce à notre méthodologie agile, nous nous adaptons rapidement aux évolutions de votre projet et aux besoins changeants de votre entreprise.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="vision-notre-agence">
          <div className="container vision ">
            <h2>{t('Home.We transform')}</h2>
            <p className="title-p">
              Vous cherchez une agence web et marketing expérimentée ? Nous sommes là ! Experts en développement web et stratégie digitale, nous créons des sites efficaces et attirons votre audience cible pour booster votre business.
              Contactez-nous dès maintenant pour une consultation gratuite.
            </p>
            <div className="grid-container-vision">
              <div className="grid-item-vision d-flex align-items-center gap-3">
                <img src={cl} alt="Clients" className="img-fluid" />
                <div className="d-flex flex-column">
                  <span className="main-text">CLIENTS</span>
                  <span className="sub-text">400+</span>
                </div>
              </div>
              <div className="grid-item-vision d-flex align-items-center gap-3">
                <img src={projets} alt="Projets" className="img-fluid" />
                <div className="d-flex flex-column">
                  <span className="main-text">PROJETS</span>
                  <span className="sub-text">625+</span>
                </div>
              </div>
              <div className="grid-item-vision d-flex align-items-center gap-3">
                <img src={garantie} alt="Garantie" className="img-fluid" />
                <div className="d-flex flex-column">
                  <span className="main-text">GARANTIE</span>
                  <span className="sub-text">6 Mois</span>
                </div>
              </div>
              <div className="grid-item-vision d-flex align-items-center gap-3">
                <img src={satisfait} alt="Satisfait" className="img-fluid" />
                <div className="d-flex flex-column">
                  <span className="main-text">CLIENT SATISFAIT</span>
                  <span className="sub-text">100%</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="meet-rec-agence">
        <div className="respect-container">
              <div className="respect-content">
              <h3>Vous avez un projet en tête ?</h3>
              </div>
            </div>
          <div className="detail-meet-rec">
            <div className="meet-rec-title-btn">
              <p className="title-p">Contactez-nous dès aujourd’hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à atteindre vos objectifs !</p>
              <MyButton />
            </div>
          </div>
        </div>
      </div>
    </>)
}

const Details = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.details-ar{
    flex-direction: row-reverse;
  }
   margin-top: 60px ;
   @media(max-width: 991px){
     margin-top: 30px;  
   }
  @media(max-width:575px){
     padding:0px 20px ;
     margin-top: 20px;
  }
  .left{
    width: 50%;
    overflow: hidden;
    border-radius: 16px;
    .image2{
      display: none;
      width: 100%;
    }
    >img{
      height: 100%;
    }
    @media (max-width:991px) and (min-width: 814px) {
      width: 48%;
      margin-right: 25px;
    }
    @media (max-width:1060px){
      .image1{
        display: none;
      }
      .image2{
        display: block;
      }
    }
  }
  .right{
    width: 50%;
    @media (max-width:991px)and (min-width: 814px) {
      width: 48%;
    }
  }
  @media (max-width:1060px){
    .left{
      width: 100% !important;
    }
    .right{
      width: 100% !important;
      padding-top: 30px;
      margin-left: -30px !important;
    }
  }
`;

const Description = styled.div`
  
  >h2{
    padding-bottom: 14px;
    text-align: left;
  }
  >.link >.joindre{
    width: auto !important;
    margin-top: 15px;
    margin-left: 0;
    padding: 10px 30px !important;
    }
`;

const Pourquoi = styled.div`
  margin-top: 140px;
  @media (max-width:991px) {
    margin-top: 62px;
   
  }
   @media(max-width:575px){
     padding:0px 20px ;
             margin-top: 50px;

  }
  >h2{
    line-height: 38px !important;
    padding-bottom: 40px;
    @media (max-width: 991px) {
      padding-bottom: 0px;
    }
  }
  
    >.left{
      width: 50%;        
      padding-right: 22px;
      @media (max-width:991px) {
        padding: 0;
      }
    }
    >.left-ar{
      padding-left: 22px;
      padding-right: 0;
    }
    >.right{
      width:50%;   
      min-width: 23drem;
      font-weight:bold;
      
      >ul{
        margin: 0;
        padding:0;
        list-style-type: none;
        list-style-image: url("/images/prestation/Path 4532.svg");
        padding-left: 42px;
        @media (max-width:991px) {
          padding-left: 10px;
        }
        >li{
          padding-left: 5px;
          padding-bottom: 10px;
          @media (max-width:500px) {
            padding-bottom: 30px;

          }
          >img{
              padding-right: 10px;
              width: 20px;
          }
          &::marker{
            border:1px solid red
          }
        }
      }
      >ul.ul-ar{
        list-style-image: url("/images/icons/arrow left.svg");
      }
    }
  }
  @media (max-width: 991px){
    >h2{
      text-align: left !important;
    }
    >.h2-ar{
      text-align: right !important;
    }

    >div{
      flex-direction: column;
    }
    justify-content: center;
    .right{
      padding-top: 10px;
      width: 100% !important;
      margin-left: 10px;
    }
    .right-ar{
      margin-right: 20px;
    }
    .left{
      width: 100% !important;
      margin-right: 20px !important;
      padding-right: 0 !important;
    }
    .left-ar{
      margin-right: 0 !important;
      margin-left: 20px !important;
    }
  }
`;

const Valeurs = styled.div`
  margin:0px 10% 60px 10%;

  >h2{
    text-align: center;
    padding-bottom: 60px;
    @media (max-width:991px) {
      text-align: left;
      padding-bottom: 15px;

    }
  }
>.row >div{
  @media (max-width:1256px) and (min-width:600px) {
    width: 50%;
  }
  @media (max-width:600px) {
    width: 100%;
  }
  
  >.card{
    padding:16px 16px 0 16px;
    min-width: 192px;
    box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
    border-radius: 10px;
    height: 100%;
    >.card-header{
      background-color: transparent;
      border-bottom: 0;
      display: flex;
      flex-direction: column;
      @media (max-width: 1436px) and (min-width: 1257px){
        height: 160px;
      }
      @media (max-width: 702px) and (min-width: 601px){
        height: 160px;
      }
      >h3{
        font-size: 20px !important;
        margin-bottom: 0;
      }
      >img{
      width: 52px;
      height: 52px;
      margin-bottom: 15px;
      } 
    }
  
    >.card-body{
      padding-top:0 !important;
      line-height: 28px;
    }
  
  @media (max-width:794px) {
    justify-content: center;
  }
  @media (max-width: 700px){
      display: flex;
      justify-content: center;
      }
  >.tek {
    >div{
      >div div{
        border: 0;          
        }
      }
  }
}
  }
`;



export default withTranslation()(Entreprise);