import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import Portfolio from "../../../Model/Portfolio";
import { Helmet } from "react-helmet-async";
import img from '../../../photos/consulting/Group 38868.png'
import responsive from "../../../photos/web/responsive.png"
import quality from "../../../photos/web/quality.png"
import security from "../../../photos/web/security.png"
import rapidity from "../../../photos/web/rapidity.png"
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './Services.css'
import MyButton from "./MyButton";
import FrequentQuestions from "./FrequentQuestions";
import CarouselWeb from "./CarouselWeb";
import { TailSpin } from "react-loader-spinner";
import { Link } from 'react-router-dom';
import {steps , questionsWeb} from "../../Data.js"


const Services = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const staticTitles = {
    en: "Développement Web",
    fr: "Développement Web"
  };
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState('true')
 
 
  useEffect(() => {
    setLangue(currentLanguage);
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  
    const fetchPortfolio = async () => {
      try {
        let allPortfolios = [];
        let page = 1;
        const pageSize = 20;
        let totalPages = 1;
        while (page <= totalPages) {
          const response = await fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=${pageSize}&page=${page}`);
          const result = await response.json();
          
          totalPages = parseInt(response.headers.get('X-WP-TotalPages'), 10);
          allPortfolios = [...allPortfolios, ...result];
          page++;
        }
        const portfolioLange = allPortfolios.filter(element => {
          if (currentLanguage === "fr") {
            return element.excerpt.rendered === "<p>French</p>\n";
          } else if (currentLanguage === "en") {
            return element.excerpt.rendered === "<p>English</p>\n";
          } else if (currentLanguage === "ar") {
            return element.excerpt.rendered === "<p>العربية</p>\n";
          }
          return false;
        });
  
        setItems(portfolioLange);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching portfolio:', error);
        setLoading(false);
      }
    };
  
    fetchPortfolio();
  }, [currentLanguage]);

  let portfolio = [];

  items.forEach(element => {
    if (element.categoriesportfolio && element.categoriesportfolio.includes(5)) {
      if (element._embedded && element._embedded['wp:featuredmedia']) {
        portfolio.push(new Portfolio(
          element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered
        ));
      } else {
        portfolio.push(new Portfolio(
          element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element.excerpt.rendered
        ));
      }
    }
  });


  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])


  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description,element.yoast_head_json.title, element.date, element.excerpt.rendered)))


  return (<>
    <Helmet>
      <title>{page[0] !== undefined && page[0].metatitle}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container" >
      <Breadcrumb>
        <p>
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Breadcrumb>
      <Title>
        <h1>{titre}</h1>

      </Title>
      {/* Pourquoi choisir notre agence */}
      <div>
        {portfolio && portfolio.length > 0 ? (
          <div className="carousel-page-web">
            <CarouselWeb items={portfolio} />
          </div>
        ) : (
          <div className="loading">
            <TailSpin color="#17406F" />
          </div>
        )}
      </div>

      <div className="choisir-notre-agence">
        <div className="respect-container">
          <div className="respect-icon">
            <img src={img} alt="Réactivité" />
          </div>
          <div className="respect-content  w-100">
            <div className="title-dev-web d-flex justify-content-between align-items-center">
              <h3>Pourquoi Choisir Notre Agence ?</h3>
              <MyButton />
            </div>

            <p className="p-dev">Notre approche éprouvée en 8 étapes pour un développement de site web sans faille</p>
          </div>
        </div>
        <div className="process-steps">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`step-container step-${index + 1} ${index % 2 === 0 ? 'left-step' : 'right-step'}`}
            >
              <div className="step-number">{step.id}</div>
              <div className="step-details">
                <h3>{step.title}</h3>
                <p className="title-p">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Pourquoi créer un site sur mesure */}
      <div className="site-sur-mesure">
        <div className="respect-container">
          <div className="respect-icon">
            <img src={img} alt="Réactivité" />
          </div>
          <div className="respect-content  w-100">
            <div className="title-dev-web d-flex justify-content-between align-items-center">
              <h3>Pourquoi créer un site sur mesure ?</h3>
              <MyButton />
            </div>
            <p className="p-dev">Voici les éléments essentiels que nous intégrons systématiquement dans tous nos projets pour garantir votre succès en ligne : </p>
          </div>
        </div>
        <div className="agence-cards-web">
          <div className="web-dev-card">
            <div className="web-dev-card-icon">
              <img src={responsive} alt="Icon 1" />
            </div>
            <h3 className="web-dev-card-title">Responsive Design</h3>
            <p className="web-dev-card-text">
              Un bon site web doit être accessible et fonctionnel sur tous les appareils, qu'il s'agisse d'ordinateurs, de tablettes ou de smartphones. Chez Oxton Digital, nous adoptons une approche de conception responsive, garantissant que votre site s'adapte automatiquement aux différentes tailles d'écran. Cela améliore non seulement l'expérience utilisateur, mais également votre référencement sur les moteurs de recherche.
            </p>
          </div>

          <div className="web-dev-card cardc2-web-dev">
            <div className="web-dev-card-icon">
              <img src={rapidity} alt="Icon 2" />
            </div>
            <h3 className="web-dev-card-title">Rapidité</h3>
            <p className="web-dev-card-text">
              La vitesse de chargement d'un site est cruciale pour retenir l'attention des visiteurs. Un site lent peut entraîner un taux de rebond élevé et nuire à votre image de marque. Notre équipe veille à optimiser chaque élément de votre site, des images aux scripts, pour assurer un temps de chargement rapide. Grâce à des techniques avancées de mise en cache et d’optimisation, nous garantissons une expérience utilisateur fluide et agréable.
            </p>
          </div>

          <div className="web-dev-card">
            <div className="web-dev-card-icon">
              <img src={security} alt="Icon 3" />
            </div>
            <h3 className="web-dev-card-title">Sécurité</h3>
            <p className="web-dev-card-text">
              La sécurité de votre site web est primordiale, tant pour protéger les données de vos utilisateurs que pour maintenir la confiance en votre marque. Oxton Digital met en œuvre les meilleures pratiques de sécurité, telles que des certificats SSL, des mises à jour régulières et des tests de vulnérabilité. Nous faisons de la protection des données une priorité, vous permettant de vous concentrer sur la croissance de votre activité sans souci.
            </p>
          </div>
          <div className="web-dev-card cardc2-web-dev">
            <div className="web-dev-card-icon">
              <img src={quality} alt="Icon 2" />
            </div>
            <h3 className="web-dev-card-title">Contenu de Qualité</h3>
            <p className="web-dev-card-text">
              Le contenu est le cœur de votre site web. Il doit être pertinent, engageant et optimisé pour le référencement. Notre équipe de rédacteurs et de spécialistes en SEO travaille main dans la main pour créer du contenu qui non seulement attire des visiteurs, mais les incite également à passer à l'action. Nous veillons à ce que chaque page soit enrichissante et en adéquation avec les attentes de votre public cible.
            </p>
          </div>
        </div>
      </div>

      {/* Questions fréquentes */}
      <div className="questions-web">
        <div className="respect-container">
          <div className="respect-content ">
            <h3>Questions fréquentes</h3>
          </div>
        </div>
        <FrequentQuestions questions={questionsWeb} />
      </div>


    </div>
  </>
  )
}

export const Expérience = styled.div`
  margin:0px 15% 80px 15%;
  padding-top: 15px;

  @media (min-width: 991px) {
    text-align: center;
  }
  @media (max-width: 1700px) {
    margin:60px 15% 40px 15%;
  }
  @media (max-width: 1600px) {
    margin:60px 15% 10px 15%;
  }
  @media (max-width: 1550px) {
    margin:60px 15% 90px 15%;
  }
  @media (max-width: 1440px) {
    margin:60px 15% 60px 15%;
  }
  @media (max-width: 991px) {
    margin:0px 13% 50px 10%;
    padding-top: 0;
  }
  @media (max-width: 460px) {
    margin:0px 16% 30px 10%;
  }
  @media (min-width: 1860px) {
    margin-left:auto;
    margin-right: auto;
  }
  max-width: 1300px;

  >h2{
    padding-bottom: 50px;
    @media (max-width: 991px) {
      padding-bottom: 0px; 
      margin-bottom :0 ;
    }
  }
  >img{
    max-height: 487px;
    @media (max-width: 1300px) {
     display: none;
   }
   width:100%;
   position: absolute;
   left: 0;
   padding: 0 14%;
   z-index: -1;
  }

  >.mobile{
    justify-content: space-between;
    @media (min-width: 1301px) {
      display: none;
    }
    >.steps{
      border: 1px solid #5AA9BA;
      border-radius:66px;
      padding: 20px;
      margin: 20px;
      text-align: center;
      >label{
        color: #20ACE3;
        font-weight: 600;
        padding: 10px 0;
        line-height: 28px;
        &:hover{
        background-color: #F9FDFF; 
        }
      }
      >img{
        width: 70px;
        margin-bottom: 20px;
     }
    }
  }
  >.mobile-ar >.steps{
    margin-right: 0;
  }
  >.web{
    height: 488px;
    @media (max-width: 1300px) {
      display: none;
    }
    @media (max-width: 1550px) {
      height: 400px;
    }

    text-align: center;
    >.steps{
      width: 20%;
      padding-top: 32px;
      height: 100%;
      &:hover, :focus{
      >.first{
        display: block;
      }
      >.second{
        display: block;
      }
      >img{
        display: none;
      }
      }
      >.second{
        display: none;
      }
      >.first{
        display: none;
        margin-bottom: 0;
        font-size: 14px;
      }
      >label{
        color: #20ACE3;
        &:hover{
        background-color: #F9FDFF; 
        }
      }
      &:nth-child(1) , :nth-child(5){
        padding-top: 125px;
      }
      &:nth-child(2) , :nth-child(4){
        padding-top: 330px;
        >p{
          position: relative;
          bottom: 100px;
        }
        &:hover{
          >label{
            position: relative;
            bottom:110px;
          }
        }
        @media (max-width:2560px) {
          padding-top: 330px;
        }
        @media (max-width:1810px) {
          padding-top: 290px;
        }
        @media (max-width:1715px) {
          padding-top: 250px;
        }
        @media (max-width:1658px) {
          padding-top: 230px;
        }
        @media (max-width:1600px) {
          padding-top: 200px;
        }
        @media (max-width:1560px) {
          padding-top: 180px;
        }
        @media (max-width:1465px) {
          padding-top: 150px;
        }
        @media (max-width:1385px) {
          padding-top: 120px;
        }
      }
      >img{
        width: 70px;
        margin-bottom: 20px;
        z-index: -1;
      }
  }
 }
`;

export const Development = styled.div`
  background-color: #EEF7FB;
  >.row{
    .left{
      padding:80px 100px 72px 15%;
      @media (max-width: 991px) {
        padding:30px 10%;
      }
      @media (max-width:1020px) {
        width: 100%;
      }
      >h2{
        margin-bottom: 10px;
      }
      >p{
        margin-bottom: 8px;
      }
    }
    .right{
      background-repeat: no-repeat;
      border-left:70px solid transparent;
      border-bottom: 306px solid #367ba7;
      @media (max-width:2388px) {
        border-bottom: 334px solid #367ba7 !important;
      }
      @media (max-width:2023px) {
        border-bottom: 362px solid #367ba7 !important;
      }
      @media (max-width:1443px) {
        border-bottom: 395px solid #367ba7 !important;
      }
      @media (max-width:1277px) {
        border-bottom: 433px solid #367ba7 !important;
      }
      @media (max-width:1246px) {
        border-bottom: 460px solid #367ba7 !important;
      }
      @media (max-width:1110px) {
        border-bottom: 490px solid #367ba7 !important;
      }
      @media (max-width:1020px) {
          display: none;
        }
      @media (max-width: 1020px) {
        border-bottom: 440px solid transparent !important;
      }
      >.phone{
        width: 240px;
        position: absolute;
        right: 200px;
        margin-top: -5px;
        @media (max-width:2388px) {
          margin-top: 10px;
        }
        @media (max-width:2023px) {
          margin-top: 20px;
        }
        @media (max-width:1443px) {
          margin-top: 40px;
        }
        @media (max-width:1277px) {
          padding-top: 20px;
        }
        @media (max-width:1246px) {
          padding-top: 40px;
        }
        @media (max-width:2560px){
          right: 240px;
        }
        @media (max-width:2500px){
          right: 230px;
        }
        @media (max-width:2460px){
          right: 220px;
        }
        @media (max-width:2360px) {
          right: 200px;
        }
        @media (max-width:2260px) {
          right: 190px;
        }
        @media (max-width:2160px) {
          right: 180px;
        }
        @media (max-width:2090px) {
          right: 160px;
        }
        @media (max-width:1990px) {
          right: 140px;
        }
        @media (max-width:1870px) {
          right: 120px;
        }
        @media (max-width:1750px) {
          right: 100px;
        }
        @media (max-width:1630px) {
          right: 80px;
        }
        @media (max-width:1500px) {
          right: 70px;
        }
        @media (max-width:1440px) {
          right: 60px;
        } 
        @media (max-width:1370px) {
          right: 40px;
        }
        @media (max-width:1250px) {
          right: 25px;
        }
        @media (max-width:1150px) {
          right: 15px;
        }
        @media (max-width:1100px) {
          right: 5px;
        }
        @media (max-width:1050px) {
          right: 0px;
        }
      }
    }
  }
`;


export default withTranslation()(Services)