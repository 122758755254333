import React, { useEffect, useState } from "react";
import "./Footer.css";
import styled from "styled-components"
import Client from "../../Model/Client";
import Carousel from 'react-multi-carousel';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Aos from "aos";
import "aos/dist/aos.css";
import Pages from "../../Model/Pages";
import { Link } from 'react-router-dom';
import Partenaire from "../../Model/Partenaire";
import Contacter from "../../Model/Contacter";
import FooterD from "../../Model/Footer";
import { URL_Back, website } from "../Pages/Variables";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";

const Footer = (props) => {
  const title = window.location.href
  const splitState = title.split('/')
  const { t } = props
  const [items, setItems] = useState([]);
  const [client, setClient] = useState([]);
  const [images, setImages] = useState([]);
  const [services, setServices] = useState(false)
  const [contact, setContact] = useState(0)
  const [partenaires, setPartenaires] = useState(0)
  const [contacter, setContacter] = useState([])
  const [footer, setFooter] = useState([])
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [reviews, setReviews] = useState([]);
  const [reviewHide, setReviewHide] = useState('');
  const [notes, setNotes] = useState(false);

  const hideReview = () => {
    setReviewHide('hide')
  }

  const responsive = {
    plasama: {
      breakpoint: { max: 3000, min: 2000 },
      items: 6
    },
    ldesktop: {
      breakpoint: { max: 2000, min: 1600 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 1600, min: 1312 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1312, min: 950 },
      items: 3
    },
    mini: {
      breakpoint: { max: 950, min: 671 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 671, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    fetch(`${URL_Back}/wp-json/wp/v2/reviews`)
      .then(res => res.json())
      .then(
        (result) => {
          setReviews(result)
        },
      )
  }, []);

  let review = []
  reviews.map(element =>
    review.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered)))


  useEffect(() => {
    setLangue(currentLanguage)
  }, [currentLanguage])
  
  
  const fetchPartners = async () => {
    const baseUrl = `${URL_Back}/wp-json/wp/v2/partenaires?website=${website}`;
    
    try {
      const response = await fetch(baseUrl);
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des données.");
      }
  
      const allItems = await response.json();
      setItems(allItems); 
    } catch (error) {
      console.error("Erreur lors du fetch :", error);
    }
  };
  
  
 useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.config({
      autoSleep: 60,
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
      units: { left: "%", top: "%", rotation: "rad" }
    });
    gsap.to(document.getElementsByClassName("down"), {
      scrollTrigger: {
        trigger: document.getElementsByClassName("images"),
        toggleActions: "restart pause reverse pause",
        start: "top center+=100px",
        end: "bottom center-=300px",
        scrub: true,
      },
      y: 100,
      x: -100,
      duration: 3,
    });

    gsap.to(document.getElementsByClassName("up"), {
      scrollTrigger: {
        trigger: document.getElementsByClassName("images"),
        toggleActions: "restart pause reverse pause",
        start: "top center+=100px",
        end: "bottom center-=300px",
        scrub: true,
      },
      y: -100,
      x: +100,
      duration: 3,
    });


    fetch(`${URL_Back}/wp-json/wp/v2/footer?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setFooter(result)
        },
      )
    fetch(`${URL_Back}/wp-json/wp/v2/clients?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setClient(result)
        },
      )
    fetch(`${URL_Back}/wp-json/wp/v2/contacter?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setContacter(result)
        },
      )

    fetchPartners();
  }, [])

  let clients = [];
  let image = [];
  client.map(element =>
    clients.push(new Client(element.id, element.title.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)));
  images.map(element =>
    image.push(new Client(element.id, element.title.rendered, element.date, element._embedded['wp:featuredmedia'][0]))
  )

  let partenaire = items
    .map(element => new Partenaire(
      element.id,
      element.title.rendered,
      element.excerpt.rendered,
      element.date,
      element.x_featured_media
    )).reverse();    
  
  let contacterEmail = []
  let contacterTel = []
  let contacterGlobal = []
  contacter.map(element =>
    element.title.rendered === "Email" &&
    contacterEmail.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)))
  contacter.map(element =>
    element.title.rendered === "Telephone" &&
    contacterTel.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)))
  contacter.map(element =>
    element.title.rendered === "Global" &&
    contacterGlobal.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)))

  let footerDescription = []
  footer.map(element =>
    element.title.rendered === "Description" &&
    (element.excerpt.rendered === "<p>العربية</p>\n" && langue === "ar"
      ? footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
      : element.excerpt.rendered === "<p>Français</p>\n" && langue === "fr"
        ? footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
        : element.excerpt.rendered === "<p>English</p>\n" && langue === "en"
        && footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
    )
  )

  let footerService = []
  footer.map(element =>
    element.title.rendered === "Services" &&
    (element.excerpt.rendered === "<p>العربية</p>\n" && langue === "ar"
      ? footerService.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
      : element.excerpt.rendered === "<p>Français</p>\n" && langue === "fr"
        ? footerService.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
        : element.excerpt.rendered === "<p>English</p>\n" && langue === "en"
        && footerService.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
    ))

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const poweredElements = Array.from(document.querySelectorAll('.wp-google-powered'));

      if (poweredElements.length > 1) {
        if (!document.querySelector('.wp-google-powered-wrapper')) {
    
          const wrapperDiv = document.createElement('div');
          wrapperDiv.className = 'wp-google-powered-wrapper';
          const parentElement = poweredElements[0]?.parentNode;
          
          if (parentElement) {
            parentElement.appendChild(wrapperDiv);
            poweredElements.forEach((element) => {
              wrapperDiv.appendChild(element); 
            });
            observer.disconnect();
          }
        }
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); 
  }, []);


  return (<>
    <div className="allFooter">
      <NosClients>
        <div className="listeOfClients">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            containerClass="carousel-container"
          >
            {clients.map((item) => {
              return item.excerpt && item.excerpt.trim().length > 0 ? (
                <a href={item.excerpt.slice(3, -5)} target="_blank" key={item.id} >
                  <img src={item.image} alt={item.title} className="img-fluid imgClients" />
                </a>
              ) : (
                <img key={item.id} src={item.image} alt={item.title} className="img-fluid imgClients" />
              );
            })}
          </Carousel>
        </div>
      </NosClients>

      <div className="footer">
        <div className={langue === "ar" ? "description description-ar" : "description"}>
          {footerDescription[0] !== undefined && <img className="logo logoFooter" src={footerDescription[0].image} alt="oxton" />}
          {footerDescription[0] !== undefined && <p dangerouslySetInnerHTML={{ __html: footerDescription[0].content }} />}
        </div>
        <div className="services">
          <div className="buttonFooter" onClick={() => services ? setServices(false) : setServices(true)}>
            <p className="title">{t('Our_services')}</p>
            <span className="hideFooter title">
              {services ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}
            </span>
          </div>
          {footerService[0] !== undefined
            && <ul className={services
              ? langue === "ar"
                ? "service service-ar show"
                : "service show"
              : langue === "ar"
                ? "service service-ar hide"
                : "service hide"} dangerouslySetInnerHTML={{ __html: footerService[0].content }} />}
        </div>
        <hr className="hideFooter" />
        <div className="contacter">
          <div className="buttonFooter" onClick={() => contact ? setContact(false) : setContact(true)}>
            <p className="title" >{t('Contact_Us')}</p>
            <span className="hideFooter title">
              {contact ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}</span>
          </div>
          <ul className={contact
            ? langue === "ar" ? "UlContacter UlContacter-ar show" : "UlContacter show"
            : langue === "ar" ? "UlContacter UlContacter-ar hide" : "UlContacter hide"}>
            {contacterGlobal.map((item, key) => (
              <li key={key}>
                <img className="contacterImg global" src={item.image} alt={item.title} />
                <a key={item.id} className="aLink LinkGlobal" href={`${item.content.slice(4, -5)}`} target="_blank">{t('Support')}<br /></a>
              </li>
            ))}
            {contacterEmail.map((item, key) => (
              <li key={key}>
                <img className="contacterImg" src={item.image} alt={item.title} />
                <a key={item.id} className="aLink" href={`mailto:${item.content.slice(4, -5)}`}>{item.content.slice(4, -5)}<br /></a>
              </li>
            ))}
            {contacterTel.map((item, key) => (
              (key === 0) ?
                <li key={key}>
                  <img className="contacterImg" src="/images/footer/tel.png" alt="telephone" />
                  <img className="contacterImg" src={item.image} alt={item.title} />
                  <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}><bdi>{item.content.slice(4, -5)}</bdi></a>
                </li> :
                <li key={key}>
                  <img className="contacterImg seconde" src={item.image} alt={item.title} />
                  <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}><bdi>{item.content.slice(4, -5)}</bdi></a>
                </li>
            ))}
          </ul>
        </div>
        <hr className="hideFooter" />
        <div className="partenaires">
          <div className="buttonFooter" onClick={() => partenaires ? setPartenaires(false) : setPartenaires(true)}>
            <div className="title">{t('Our_Partners')}</div>
            <span className="hideFooter title">
              {partenaires ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}</span>
          </div>
          <div className={partenaires ? "group show groupShow" : "group hide grouphide"}>
            {partenaire.map(item => (

              <a href={item.excerpt.slice(3, -5)} target="_blank" key={item.id} >
                <img className={langue === "ar" ? "partenaire partenaire-ar" : "partenaire"} src={item.image} alt={item.title} />
              </a>
            ))}
          </div>
        </div>
        <hr className="hideFooter" />
      <div className="notes">
        <div className="buttonFooter" onClick={() => setNotes(!notes)}>
          <p className="title">La parole à nos clients</p>
          <span className="hideFooter title">
            {notes ? (
              <img src="/images/footer/arrow_up.svg" alt="arrow_up" />
            ) : (
              <img src="/images/footer/arrow_down.svg" alt="arrow_down" />
            )}
          </span>
        </div>
        <div className={notes ? "show groupShow" : "hide grouphide"}>
          <a
            href="https://www.codeur.com/-oxton-digital"
            title="oxton-digital"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.codeur.com/widgets/badge.svg?k=n9lqoPTmi8DF3Sxc"
              title="oxton-digital — Codeur.com"
              alt="Codeur.com Badge"
            />
          </a>
          <Reviews
            className={langue === "ar" ? `${reviewHide} review-ar` : `${reviewHide}`}
          >
            {reviews[0] !== undefined && (
              <a
                className={`${reviewHide}`}
                href="https://www.google.com/maps/place/Oxton+Digital/@48.9825979,2.2714041,17z/data=!4m7!3m6!1s0x0:0x138c72dfcc846902!8m2!3d48.9825979!4d2.2714041!9m1!1b1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div dangerouslySetInnerHTML={{ __html: review[0].content }} />
              </a>
            )}
          </Reviews>
        </div>
        </div>

      </div>
      <div className="copyright d-flex">
        <bdi><span> Copyright &copy; {new Date().getFullYear()} - Oxton Digital.</span>
          <Link to={`/${langue}/cgv`}>CGV |&nbsp;</Link>
          <Link to={langue === "fr" ? "/fr/mentions-legales" : langue === "en" ? "/en/terms-of-use" : "/ar/تعليمات-الاستخدام"}>{t('Legal')}</Link></bdi>
      </div>
    </div>
  </>)
}
const Reviews = styled.div`
  // position: fixed;
  right: 50px;
  bottom: 80px;
  z-index: 200;

  @media (max-width: 991px) {
    right: 5px;
    bottom: 5px;
  }
  >svg{
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #efefef;
    padding: 3px;
    cursor: pointer;
  }
  >a{
    text-decoration: none;
  }
  >.hide {
    display: none;
  }
  .wp-google-list{
    color: #17406F !important;
    background: #efefef;
    padding: 7px;
    width: 110px;
    border-radius: 30px;
    text-align: center;
    margin-left: auto;
    border: 2px solid #fff;
  }
  .wp-google-rating{
    display: block;
    font-size: 24px;
    text-align: center;
  }
  .wp-google-powered{
    font-size: 11px;
    text-decoration: none;
    >img{
      padding-top: 10px;
      padding-bottom: 10px;
      height: auto;
      width: 85px;
    }
  }
  .wp-google-left{
    display: none;
  }
  .wp-google-name{
    display: none;
  }
`;
const NosClients = styled.div`
  
  
  >h2{
    text-align:center;
    padding-bottom: 40px;
  }
  >.listeOfClients >.carousel-container{
      // margin-top:150px;
      padding: 0px !important;
      // @media(max-width: 575px){
      //    margin-top: 80px ;
      //    }
      
    //   @media(max-width: 371px){
    //     padding-left: 45px ;
    //   }
    //   @media(max-width: 380px) and (min-width: 372px) {
    //     padding-left: 52px ;
    //   }
    //   @media(max-width: 384px) and (min-width: 381px) {
    //     padding-left: 55px ;
    //   }
    //   @media(max-width: 391px) and (min-width: 385px) {
    //     padding-left: 60px ;
    //   }
    //   @media(max-width: 400px) and (min-width: 391px) {
    //     padding-left: 65px ;
    //   }
    //   @media(max-width: 411px) and (min-width: 401px) {
    //     padding-left: 70px ;
    //   }
    //   @media(max-width: 420px) and (min-width: 411px) {
    //     padding-left: 75px ;
    //   }
    //   @media(max-width: 424px) and (min-width: 421px) {
    //     padding-left: 80px ;
    //   }
    //   @media(max-width: 440px) and (min-width: 425px) {
    //     padding-left: 60px ;
    //   }
    //   @media(max-width: 450px) and (min-width: 441px) {
    //     padding-left: 70px ;
    //   }
    //   @media(max-width: 457px) and (min-width: 451px){
    //     padding-left: 75px ;
    //   }
    //   @media(max-width: 471px) and (min-width: 458px){
    //     padding-left: 60px ;
    //   }
    //   @media(max-width: 480px) and (min-width: 472px){
    //     padding-left: 70px ;
    //   }
    //   @media(max-width: 500px) and (min-width: 481px){
    //     padding-left: 80px ;
    //   }
    //   @media(max-width: 520px) and (min-width: 501px){
    //     padding-left: 90px ;
    //   }
    //   @media(max-width: 550px) and (min-width: 521px){
    //     padding-left: 100px ;
    //   }
        
    //   @media(max-width: 600px) and (min-width: 551px){
    //     padding-left: 120px;
    //   }
    //   @media(max-width: 631px) and (min-width: 601px){
    //     padding-left: 140px;
    //   }
    //   @media(max-width: 671px) and (min-width: 632px){
    //     padding-left: 160px;
    //   }
    //   @media(max-width: 705px) and (min-width: 672px){
    //     padding-left: 35px;
    //   }
    //   @media(max-width: 720px) and (min-width: 706px){
    //     padding-left: 40px;
    //   }
    //   @media(max-width: 745px) and (min-width: 721px){
    //     padding-left: 45px;
    //   }
    //   @media(max-width: 760px) and (min-width: 746px){
    //     padding-left: 50px;
    //   }
    //   @media(max-width: 790px) and (min-width: 761px){
    //     padding-left: 55px;
    //   }
    //   @media(max-width: 820px) and (min-width: 791px){
    //     padding-left: 60px;
    //   }
    //   @media(max-width: 836px) and (min-width: 821px){
    //     padding-left: 70px;
    //   }
    //   @media(max-width: 890px) and (min-width: 837px){
    //     padding-left: 70px;
    //   }  
    //   @media(max-width: 926px) and (min-width: 891px){
    //     padding-left: 80px;
    //   }      
    //   @media(max-width: 950px) and (min-width: 927px){
    //     padding-left: 90px;
    //   }
    //   @media(max-width: 991px) and (min-width: 951px){
    //     padding-left: 40px;
    //   }
    //   @media(max-width: 1015px) and (min-width: 992px){
    //     padding-left: 35px;
    //   }
    //   @media(max-width: 1060px) and (min-width: 1016px){
    //     padding-left: 40px;
    //   }
    //   @media(max-width: 1080px) and (min-width: 1061px){
    //     padding-left: 45px;
    //   }
    //   @media(max-width: 1100px) and (min-width: 1081px){
    //     padding-left: 55px;
    //   }
    //   @media(max-width: 1140px) and (min-width: 1101px){
    //     padding-left: 60px;
    //   }
    //   @media(max-width: 1286px) and (min-width: 1141px){
    //     padding-left: 70px;
    //   }
    //   @media(max-width: 1312px) and (min-width: 1286px){
    //     padding-left: 90px;
    //   }
    //   @media(max-width: 1400px) and (min-width: 1312px){
    //     padding-left: 45px;
    //   }
    //   @media(max-width: 1500px) and (min-width: 1400px){
    //     padding-left: 60px;
    //   } 
    //   @media(max-width: 1600px) and (min-width: 1500px){
    //     padding-left: 70px;
    //   }  
    //   @media(max-width: 1910px) and (min-width: 1601px){
    //     padding-left: 40px;
    //   }  
    //   @media(max-width: 2271px) and (min-width: 1911px){
    //     padding-left: 60px;
    //   }
    //   @media(max-width: 2156px) and (min-width: 2271px){
    //     padding-left: 50px;
    //   }
    //   @media(max-width: 2297px) and (min-width: 2156px){
    //     padding-left: 60px;
    //   }
    //   @media(min-width: 2297px){
    //     padding-left: 80px;
    // }
    
    .imgClients{
      

        max-width: 210px !important;
      
      @media (max-width:991px) {
        max-width: 200px !important;
      }
      @media (max-width:457px) {
        max-width: 170px !important;
      }

      @media (max-width:417px) and (min-width:375px) {
        max-width: 200px;
      }
    }  
  }

  .react-multi-carousel-track {
    align-items: center;
    text-align: center;
    background-color:#17406F;
    height:110px;
  }

  .react-multiple-carousel__arrow--left::before, .react-multiple-carousel__arrow--right::before {
    color: transparent;
  }
  .react-multiple-carousel__arrow{
    background: transparent;
  }
  .react-multiple-carousel__arrow--left::before {
    background: url('/images/home/left.svg') no-repeat;
    width: 32px;
    @media (max-width: 380px) {
      right: 25px;
    }
    @media (max-width: 671px)and (min-width: 381px){
      right: 15px;
    }
    @media (max-width: 837px)and (min-width: 672px){
      right: 48px;
    }
    @media (max-width: 1084px)and (min-width: 837px){
      right: 60px;
    }
    @media (max-width: 1244px)and (min-width: 1084px){
      right: 55px;
    }
    @media (max-width: 1582px) and (min-width: 1244px){
      right: 80px;
    }
    @media (max-width: 1832px) and (min-width: 1583px){
      right: 80px;
    }
    @media(min-width: 1833px){
      right:87px
    }
  }
  .react-multiple-carousel__arrow--right::before {
    background: url('/images/home/right.svg') no-repeat;
    left:30px;
    width: 30px;

    
    @media (max-width: 380px) {
      left: 25px;
    }
    @media (max-width: 671px)and (min-width: 381px){
      left: 15px;
    }
    @media (max-width: 692px) and (min-width: 672px){
      left: 55px;
    }
    @media (max-width: 953px) and (min-width: 693px){
      left: 45px;
    }
    @media (max-width: 1051px) and (min-width: 954px){
      left:68px;
    }
    @media (max-width: 1176px) and (min-width: 1051px){
      left: 65px;
    }
    @media (max-width: 1287px) and (min-width: 1177px){
      left: 75px;
    }
    @media (max-width: 1440px) and (min-width: 1287px){
      left: 80px;
    }
    @media(max-width: 2000px) and (min-width: 1441px){
      left: 87px;
    }
    @media(max-width: 2101px) and (min-width: 2001px){
      left: 107px;
    }
    @media(min-width: 2101px){
      left: 107px;
    }
  }
`;

export default withTranslation()(Footer)