import React, { useState, useEffect } from "react";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import img from '../../../photos/consulting/Group 38868.png'
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './ServiceDesign.css'
import FrequentQuestions from "./FrequentQuestions";
import { Link } from 'react-router-dom';


const ServiceDesign = (props) => {
    const title = props.location.pathname
    const splitState = title.split('/');
    const staticTitles = {
        en: "Qu’est-ce qu’une Agence UI/UX ?",
        fr: "Qu’est-ce qu’une Agence UI/UX ?"
      };
    const { t } = props
    const [pages, setPages] = useState([]);
    const [langue, setLangue] = useState("fr");
    const currentLanguage = cookies.get('i18next') || 'fr'
    const [titre, setTitre] = useState(staticTitles[currentLanguage]);
    const [lastChecked, setLastChecked] = useState(null);
    const questions = [
        {
            question: "Comment le design UI/UX de Oxton Digital peut-il améliorer l'expérience utilisateur de mon site ?",
            answer: "Le design UI/UX de Oxton Digital améliore l'expérience utilisateur en rendant votre site intuitif et engageant. Nous créons des interfaces claires et esthétiques qui guident les utilisateurs de manière fluide, réduisant ainsi le taux de rebond et augmentant le taux de conversion. Grâce à une recherche approfondie et à des tests d'usabilité, nous assurons que chaque interaction soit agréable et efficace, maximisant ainsi la satisfaction client !",
        },
        {
            question: "Combien de temps dure généralement un projet de design UX/UI avec votre agence ?",
            answer: "Un projet de design UX/UI chez Oxton Digital dure généralement entre 4 à 12 semaines, selon sa complexité. Ce délai inclut toutes les étapes, de la recherche initiale au prototypage et aux tests. Nous nous engageons à livrer des résultats de qualité tout en respectant vos délais !",
        },
        {
            question: "Comment mesurez-vous le succès d'un projet UX/UI ?",
            answer: `Nous mesurons le succès d'un projet UX/UI chez Oxton Digital par des KPIs clés tels que le taux de conversion, la satisfaction client et le temps passé sur le site. Grâce à des tests d'usabilité et des retours d'utilisateur, nous ajustons le design pour garantir son efficacité. Votre succès est notre priorité !`,
        },
        {
            question: "Puis-je intégrer des fonctionnalités spécifiques dans mon projet UI/UX ?",
            answer: "Absolument ! Chez Oxton Digital, nous croyons en une approche sur mesure. Nous collaborons étroitement avec vous pour intégrer des fonctionnalités spécifiques, qu'il s'agisse de systèmes de paiement, de notifications push ou d'outils d'analyse. Votre projet est unique, et nous nous engageons à le rendre aussi performant que possible !",
        },
        {
            question: "Offrez-vous un suivi après le lancement de l'application ou du site ?",
            answer: `Oui, chez Oxton Digital, nous proposons un suivi complet après le lancement de votre application ou site. Cela inclut des services de maintenance, des mises à jour régulières et des analyses de performance pour optimiser l'expérience utilisateur. Nous restons à vos côtés pour assurer le succès continu de votre projet !`,
        },
       
    ];
    const data = [
        {
            kpi: 'Taux de Conversion',
            impact: 'Un design efficace augmente les conversions de 20 à 30%.',
        },
        {
            kpi: 'Satisfaction Client',
            impact: "Des interfaces intuitives améliorent la satisfaction client jusqu'à 85%.",
        },
        {
            kpi: 'Temps Passer sur le Site',
            impact: 'Un bon design peut doubler le temps passer par les utilisateurs sur le site.',
        },
        {
            kpi: 'Taux de Rebond',
            impact: 'Un design clair réduit le taux de rebond de 25% en moyenne.',
        },
        {
            kpi: 'Engagement Utilisateur',
            impact: "Un bon UX design favorise un engagement accru, mesuré par les interactions.",
        },
        {
            kpi: 'Retour sur Investissement (ROI)',
            impact: "Des améliorations UX peuvent entraîner un ROI supérieur de 200%.",
        },
        {
            kpi: 'Coût d’Acquisition Client (CAC)',
            impact: 'Un design efficace réduit le CAC de 15 % grâce à une meilleure rétention.',
        },
        {
            kpi: 'Fidélisation Client',
            impact: 'Un bon design renforce la fidélité, augmentant la rétention de 10 à 20%.',
        },
    ];
    useEffect(() => {
        setLangue(currentLanguage)
        document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
    }, [currentLanguage])

    useEffect(() => {
        setLangue(currentLanguage)
        document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
      }, [currentLanguage])
    
      const cacheTitle = (result) => {
        const page = result.find(element =>
          decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
        );
    
        if (page) {
          const title = page.title.rendered.replace("&#8217;", "'");
          if (title !== titre) {
            setTitre(title);
          }
          setLastChecked(new Date().getTime()); 
        }
      };
    
      useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname);
    
        const oneMonth = 30 * 24 * 60 * 60 * 1000;
        const lastCheckedTimestamp = lastChecked || 0;
    
        if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
          fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
            .then(res => res.json())
            .then((result) => {
              setPages(result);
              cacheTitle(result);
            });
        }
      }, [lastChecked]);
     
    let page = []
    pages.map(element =>
        decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
        page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.yoast_head_json.title, element.date, element.excerpt.rendered)))

 

    return (
        <>
            <Helmet>
                <title>{page[0] !== undefined && page[0].metatitle}</title>
                <meta name="description" content={page[0] !== undefined && page[0].description} />
                <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
            </Helmet>
            <div className="container" >
            <Breadcrumb>
              <p>
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>UI / UX Design</span>
        </p> 
            </Breadcrumb>
                <Title>
                    <h1>{titre}</h1>
                  
                    <p className="title-p mb-3">Chez Oxton Digital, nous sommes bien plus qu'une simple agence UI/UX. Nous sommes des artisans de l'expérience numérique, dédiés à transformer vos idées en produits exceptionnels. Notre rôle est de concevoir des interfaces intuitives et engageantes qui captivent vos utilisateurs dès le premier contact. L'UI (interface utilisateur) et l'UX (expérience utilisateur) ne sont pas seulement des buzzwords ; ils sont essentiels pour le succès de votre projet. </p>
                    <p className="title-p mb-0">Dans un monde numérique où l’attention des utilisateurs est limitée, un design bien pensé fait toute la différence. Avec Oxton Digital, vous bénéficiez d’une approche stratégique qui allie esthétique et fonctionnalité, garantissant que chaque interaction avec votre produit soit fluide et mémorable. Investir dans l’UI/UX, c'est investir dans la satisfaction client et la fidélité, deux piliers cruciaux pour le développement durable de votre entreprise. Ensemble, donnons vie à votre projet et créons une expérience utilisateur qui se démarque !</p>
                </Title>
                <div className="capacity-container">
                    <div className="respect">
                        <div className="respect-container">
                            <div className="respect-icon">
                                <img src={img} alt="Réactivité" />
                            </div>
                            <div className="respect-content">
                                <h3>Quelles Capacités une Agence Doit-elle Avoir pour Fournir les Meilleurs Résultats ?</h3> </div>
                        </div>
                    </div>
                    <div className="content-capacity">
                        <p className="title-p">Pour offrir des résultats de qualité, une agence UI/UX comme Oxton Digital doit posséder des compétences essentielles. Nous commençons par la recherche utilisateur, qui nous permet de comprendre les besoins et les attentes de votre public cible. Cette compréhension est la base de tout bon design.</p>
                        <p className="title-p">Ensuite, notre équipe excelle en design graphique et en prototypage. Nous créons des maquettes interactives qui vous permettent de visualiser et de tester vos idées avant leur lancement. Nos tests d’usabilité garantissent que chaque élément fonctionne parfaitement, rendant l'expérience utilisateur fluide et agréable.</p>
                        <p className="title-p">Enfin, chez Oxton Digital, nous utilisons les dernières technologies et restons à jour sur les tendances du secteur. Cela nous permet de vous fournir des solutions modernes et efficaces. Avec nous, vous avez l’assurance que votre projet bénéficiera des meilleures pratiques en UI/UX pour un impact maximal.</p>
                    </div>
                </div>
       
                <div className="impact-table-container">
                    <div className="respect">
                        <div className="respect-container">
                            <div className="respect-content">
                                <h3>Impact du Design UI/UX sur les KPIs</h3>
                            </div>
                        </div>
                    </div>
                    <div className="impact-container"> 
                    <table className="impact-table">
                        <thead>
                            <tr>
                                <th>KPI</th>
                                <th>Impact du Design UI/UX</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.kpi}</td>
                                    <td>{row.impact}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    <div className="cta-container">
                        <p>Prêt à Transformer Votre Projet</p>
                        <button>
                            <span className="d-flex gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" fill="none">
                                    <path d="M15.4 13.6532H6.6C5.3867 13.6532 4.4 14.5952 4.4 15.7537V17.8542C4.4 19.0126 5.3867 19.9546 6.6 19.9546H15.4C16.6133 19.9546 17.6 19.0126 17.6 17.8542V15.7537C17.6 14.5952 16.6133 13.6532 15.4 13.6532ZM16.5 17.8542C16.5 18.4328 16.0061 18.9044 15.4 18.9044H6.6C5.9939 18.9044 5.5 18.4328 5.5 17.8542V15.7537C5.5 15.175 5.9939 14.7034 6.6 14.7034H15.4C16.0061 14.7034 16.5 15.175 16.5 15.7537V17.8542ZM4.4 9.97732C4.4 9.68746 4.6464 9.4522 4.95 9.4522H9.35C9.6536 9.4522 9.9 9.68746 9.9 9.97732C9.9 10.2672 9.6536 10.5024 9.35 10.5024H4.95C4.6464 10.5024 4.4 10.2672 4.4 9.97732ZM4.4 5.77634C4.4 5.48648 4.6464 5.25122 4.95 5.25122H9.35C9.6536 5.25122 9.9 5.48648 9.9 5.77634C9.9 6.06621 9.6536 6.30147 9.35 6.30147H4.95C4.6464 6.30147 4.4 6.06621 4.4 5.77634ZM19.9056 5.65977L16.0721 1.99967C14.7213 0.709965 12.9261 0 11.0165 0H4.95C2.2209 0 0 2.12044 0 4.7261V20.4798C0 23.0854 2.2209 25.2059 4.95 25.2059H17.05C19.7791 25.2059 22 23.0854 22 20.4798V10.4867C22 8.66347 21.2564 6.94842 19.9056 5.65977ZM19.1279 6.40229C19.7329 6.97992 20.1828 7.66258 20.482 8.40196H14.8489C13.9392 8.40196 13.1989 7.69514 13.1989 6.82659V1.44829C13.9733 1.735 14.6883 2.16455 15.2933 2.74114L19.1268 6.40124L19.1279 6.40229ZM20.9 20.4798C20.9 22.5067 19.173 24.1556 17.05 24.1556H4.95C2.827 24.1556 1.1 22.5067 1.1 20.4798V4.7261C1.1 2.69913 2.827 1.05024 4.95 1.05024H11.0165C11.385 1.05024 11.7458 1.09015 12.1 1.15107V6.82659C12.1 8.27383 13.3342 9.4522 14.85 9.4522H20.7944C20.8582 9.79143 20.9 10.1359 20.9 10.4867V20.4798Z" fill="white" />
                                </svg>
                                Contactez-Nous Pour Un Devis
                            </span>
                        </button>
                    </div>
                </div>
             <div className="faq-ui-ux">
             <div className="respect">
                        <div className="respect-container">
                            <div className="respect-content">
                                <h3>FAQ</h3>
                            </div>
                        </div>
                    </div>
                <div className="fq-design">
                <FrequentQuestions  questions={questions}/>
                </div>
             </div>

            </div>
        </>
    )
}



export default withTranslation()(ServiceDesign)