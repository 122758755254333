import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back } from "../Variables";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";
import "./DetailBlog.css";
import im1 from '../../../photos/blog/blog1.png';
import im2 from '../../../photos/blog/blog.png';
import calendar from "../../../photos/blog/calender-icon.svg";


const DetailBlog = (props) => {
  const { slug } = useParams();
  const { t } = props;
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr';
  const [details, setDetails] = useState(null);
  const [contentText, setContentText] = useState("");
  const [dateText, setDateText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    setLangue(currentLanguage);
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  }, [currentLanguage]);
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []); 
  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const response = await fetch(`${URL_Back}/wp-json/wp/v2/posts?slug=${slug}`);
        const result = await response.json();

        if (result && result.length > 0) {
          setDetails(result[0]);
        } else {
          console.error("No post found");
          setDetails(null);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
        setDetails(null);
      }
    };
    fetchPostDetails();
  }, [slug]);

  useEffect(() => {
    if (details && details.content && details.content.rendered) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(details.content.rendered, "text/html");

      const contentDiv = doc.querySelector(".content");
      const content = contentDiv ? contentDiv.innerHTML : "";
      setContentText(content);

      const dateDiv = doc.querySelector(".date");
      const date = dateDiv ? dateDiv.textContent : "";
      setDateText(date);
    }
  }, [details]);

  const fetchRecentPosts = async () => {
    try {
      const response = await fetch(`${URL_Back}/wp-json/wp/v2/posts?per_page=3`);
      const result = await response.json();
      setRecentPosts(result);
    } catch (error) {
      console.error("Error fetching recent posts:", error);
    }
  };

  useEffect(() => {
    fetchRecentPosts();
  }, []);

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    try {
      const response = await fetch(`${URL_Back}/wp-json/wp/v2/posts?search=${searchQuery}`);
      const result = await response.json();
      setRecentPosts(result);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };


  useEffect(() => {
    const fetchPostsBySearch = async () => {
      if (!searchQuery.trim()) {
        fetchRecentPosts();
        return;
      }

      try {
        const response = await fetch(`${URL_Back}/wp-json/wp/v2/posts?search=${searchQuery}`);
        const result = await response.json();
        setRecentPosts(result);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    fetchPostsBySearch();
  }, [searchQuery]);
  
  const decodeHTML=(html)=>{
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent || html;
  }

 

  const url = details && details.article_img1_content && details.article_img1_content[0]
    ? details.article_img1_content[0].src
    : null;

 


  return (
    <>
      {details && details.title && details.title.rendered && (
        <Helmet>
          <title>Oxton Digital | Blog : {decodeHTML(details.title.rendered)}</title>
          <meta name="description" content={contentText.substring(0, 150)} />
        </Helmet>
      )}
      <div className="container">
        <Breadcrumb>
          <p>
            <Link className="retour" to="/">{t('Home_link')}</Link> / <Link className="retour" to="/blog">Blog</Link> / <span>{details && details.title ? decodeHTML(details.title.rendered) : 'Loading...'}</span>
          </p>
        </Breadcrumb>
        <Title></Title>
        <div className="detail-blog blog-detail-content">
          <div className="blog-post">
            <div className="blog-image-container">
              <img src={url} alt={details ? decodeHTML(details.title.rendered) : 'Loading...'} className="blog-image img-fluid" />

            </div>

            <h1 className="blog-title">{details ? decodeHTML(details.title.rendered) : 'Loading...'}</h1>
            <div className="blog-meta">
              <span className="blog-date d-flex gap-2">
                {dateText}
              </span>
            </div>
            <p className="blog-content title-p" dangerouslySetInnerHTML={{ __html: contentText }} />

          </div>

          <aside className="recent-posts">
            {/* <div className="search-bar">
              <div className="search-input-container">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={handleSearch} className="search-icon" width="27" height="27" viewBox="0 0 27 27" fill="none">
                  <path d="M26.7444 25.5311L19.7733 18.6715C21.5988 16.6882 22.7204 14.0652 22.7204 11.1789C22.7195 5.00458 17.6338 0 11.3598 0C5.08573 0 0 5.00458 0 11.1789C0 17.3533 5.08573 22.3579 11.3598 22.3579C14.0706 22.3579 16.5569 21.4202 18.5099 19.8615L25.508 26.7481C25.849 27.084 26.4026 27.084 26.7436 26.7481C27.0853 26.4122 27.0853 25.867 26.7444 25.5311ZM11.3598 20.6379C6.05125 20.6379 1.74786 16.403 1.74786 11.1789C1.74786 5.95486 6.05125 1.71994 11.3598 1.71994C16.6683 1.71994 20.9717 5.95486 20.9717 11.1789C20.9717 16.403 16.6683 20.6379 11.3598 20.6379Z" fill="#82878B" />
                </svg>
                <input
                  type="text"
                  placeholder="Recherche..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                />
              </div>
            </div> */}
            <h3 className="recent-title">Derniers articles</h3>
            <ul>
              {recentPosts.map((post) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(post.content.rendered, "text/html");
                const dateDiv = doc.querySelector(".date");
                const dateText = dateDiv ? dateDiv.textContent : "";
                return (
                  <li key={post.id} className="recent-post-item">
                    <Link to={`/fr/blog/${post.slug}`}>
                      <img
                        src={post.article_img3_content[0].src}
                        alt={post.title.rendered}
                        className="recent-post-image"
                      />
                      <div className="recent-post-info">
                        <span className="recent-post-date">{dateText}</span>
                        <p className="recent-post-title title-p">{decodeHTML(post.title.rendered)}</p>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>

          </aside>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(DetailBlog);
