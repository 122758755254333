import React, { useEffect, useState } from "react";
import BlogCard from './BlogCard';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from '../Variables';
import ReactGA from "react-ga";
import cookies from "js-cookie";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import './BlogPage.css';

const BlogPage = (props) => {
  const title = props.location.pathname;
  const splitState = title.split('/');
  const staticTitles = {
    en: "Dernières actualités et blogs",
    fr: "Dernières actualités et blogs"
  };

  const { t } = props;
  const [pages, setPages] = useState([]);
  const [posts, setPosts] = useState([]);
  const currentLanguage = cookies.get('i18next') || 'fr';
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading , setLoading]=useState()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


 const fetchPosts = async () => {
  try {
    setLoading(true); 
    const response = await fetch(`${URL_Back}/wp-json/wp/v2/posts`);
    const result = await response.json();
    setPosts(result);
  } catch (error) {
    console.error("Erreur lors de la récupération des données :", error);
  } finally {
    setLoading(false); 
  }
};
  useEffect(() => {
    fetchPosts();
  }, []);

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    try {
      const response = await fetch(`${URL_Back}/wp-json/wp/v2/posts?search=${searchQuery}`);
      const result = await response.json();
      setPosts(result);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };


  useEffect(() => {
    const fetchPostsBySearch = async () => {
      if (!searchQuery.trim()) {
        fetchPosts();
        return;
      }

      try {
        const response = await fetch(`${URL_Back}/wp-json/wp/v2/posts?search=${searchQuery}`);
        const result = await response.json();
        setPosts(result);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    fetchPostsBySearch();
  }, [searchQuery]);


  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);

  let page = [];
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.yoast_head_json.title, element.date, element.excerpt.rendered))
  );

  return (
    <>
      <Helmet>
        <title>{page[0] !== undefined && page[0].metatitle}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="blog-page container">
        <Breadcrumb>
          <p>
            <Link className="retour" to="/">{t('Home_link')}</Link> / <span>Blog</span>
          </p>
        </Breadcrumb>
        <Title>
          <h1>{titre}</h1>
          <div className="d-flex search-title-block">
          <p className="title-p">Boostez votre visibilité digitale avec nos conseils d’experts ! Astuces, stratégies et tendances pour attirer plus de visiteurs et convertir vos prospects en clients dès aujourd’hui.</p>
          
        <div className="search-bar">
          <div className="search-input-container">
            <svg xmlns="http://www.w3.org/2000/svg" onClick={handleSearch} className="search-icon" width="27" height="27" viewBox="0 0 27 27" fill="none">
              <path d="M26.7444 25.5311L19.7733 18.6715C21.5988 16.6882 22.7204 14.0652 22.7204 11.1789C22.7195 5.00458 17.6338 0 11.3598 0C5.08573 0 0 5.00458 0 11.1789C0 17.3533 5.08573 22.3579 11.3598 22.3579C14.0706 22.3579 16.5569 21.4202 18.5099 19.8615L25.508 26.7481C25.849 27.084 26.4026 27.084 26.7436 26.7481C27.0853 26.4122 27.0853 25.867 26.7444 25.5311ZM11.3598 20.6379C6.05125 20.6379 1.74786 16.403 1.74786 11.1789C1.74786 5.95486 6.05125 1.71994 11.3598 1.71994C16.6683 1.71994 20.9717 5.95486 20.9717 11.1789C20.9717 16.403 16.6683 20.6379 11.3598 20.6379Z" fill="#82878B" />
            </svg>
            <input
              type="text"
              placeholder="Recherche..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            />
          </div>
        </div>
        </div>
        </Title>


        <div className="blog-grid mt-5">
          {(posts.length === 0 && loading ===false) ? (
            <p className="title-p">Aucun article disponible pour le moment.</p> 
          ) : (
            posts.map((post) => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(post.content.rendered, "text/html");
              const dateDiv = doc.querySelector(".date");
              const dateText = dateDiv ? dateDiv.textContent : "";

              return (
                <Link
                  to={{
                    pathname: `/blog/${post.slug}`,
                    state: { postData: posts }
                  }}
                  style={{ textDecoration: 'none', color: 'initial' }}
                  key={post.id}
                >
                  <BlogCard
                    blog={{
                      id: post.id,
                      title: post.title.rendered,
                      date: dateText,
                      description: post.yoast_head_json.description,
                      imageUrl: post.x_featured_media_original || "",
                      link: {
                        pathname: `/blog/${post.slug}`,
                        state: { postData: posts }
                      },
                    }}
                  />
                </Link>
              );
            })
          )}
        </div>

      </div>
    </>
  );
};

export default withTranslation()(BlogPage);
