import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/pagination'; // Import Swiper pagination styles
import './ServicesCarousel.css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { services } from "../../Data.js"


const ServicesCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <div className='carousel-services-container'>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={4}
        loop={true}
        onSlideChange={handleSlideChange}

        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          1440: { slidesPerView: 4 },
          1024: { slidesPerView: 3 },
          600: { slidesPerView: 2 },
          0: { slidesPerView: 1 },
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          renderBullet: (index, className) => {
            if (index < 7) {
              return `<span class="${className}"></span>`;
            } else {
              return '';
            }
          },
        }}
      >
        {services.map((service, index) => {
          return (
            <SwiperSlide key={index}>
              <a href={service.link} className="service-wrapper" target="_blank" rel="noopener noreferrer">
                <div className="service-card">
                  <div className="carousel-service-icon">
                    {typeof service.icon === 'string' ? (
                      <img src={service.icon} alt={service.title} />
                    ) : (
                      service.icon
                    )}
                  </div>
                  <div>
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                  </div>
                </div>
              </a>
            </SwiperSlide>

          );
        })}
      </Swiper>
    </div>
  );
};

export default ServicesCarousel;
