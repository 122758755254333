import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { URL_Back, URL_Dev } from "../Variables";
import { Helmet } from "react-helmet-async";
import Pages from "../../../Model/Pages";
import { Title, Breadcrumb } from "../../../css/commun";
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import './Cm.css'
import ReactGA from "react-ga";
import cookies from "js-cookie";
import img from '../../../photos/consulting/Group 38868.png'

import {actions , servicesCm} from "../../Data"
import consulting from "../../../photos/consulting.svg"
import prestation from "../../../photos/sous-traitance.svg"
import MyButton from "./MyButton";


const Cm = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const staticTitles = {
    en: "Social Media",
    fr: "Social Media"
  };
  const [pages, setPages] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [langue, setLangue] = useState("fr");
  const [presence, setPresence] = useState(300);
  const [active, setActive] = useState(1)
  const [visibilite, setVisibilite] = useState(580);
  const [performance, setPerformance] = useState(1190)


  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);


  useEffect(() => {
    const handleResize = () => {
      const isSmall = window.matchMedia("(max-width: 575px)").matches;
      setIsSmallScreen(isSmall);
      if (isSmall) {
        setShowAll(false); 
      } else {
        setShowAll(true); 
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize(); 

    return () => window.removeEventListener("resize", handleResize);
  }, []);

 
 
  const visibleActions = showAll ? actions : actions.slice(0, 5);

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime()); 
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description,element.yoast_head_json.title, element.date, element.excerpt.rendered)))

  return (<>
    <Helmet>
      <title>{page[0] !== undefined && page[0].metatitle}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container" >
      <Breadcrumb>
        <p>
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Breadcrumb>
      <Title>
        <h1>{titre}</h1>
        <p className="title-p">En pleine ère numérique, l'art du Community management est devenu un pilier indispensable pour toute entreprise cherchant à prospérer en ligne. Chez Oxton Digital, nous comprenons l'impact puissant d'une gestion de communauté efficace et personnalisée. Notre expertise en SEO et Community management transforme votre présence en ligne en un espace dynamique et engageant, où chaque interaction compte. Nous vous accompagnons pour créer des liens durables avec votre audience, renforcer votre marque et stimuler votre croissance professionnelle. Avec Oxton Digital, transformez vos followers en véritables ambassadeurs de votre marque.</p>
        {/* <a className="link" href="#section">
          <button className="button-formules">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32" fill="none">
              <path d="M10.2015 26.397C11.8839 26.397 13.5032 25.9842 14.9504 25.2103L17.5596 29.7518C18.9294 32.3024 22.6558 32.7808 24.6267 30.6786C26.7067 28.6834 26.2253 24.9097 23.7119 23.5177C23.712 23.5177 19.2187 20.867 19.2187 20.867C20.501 18.3802 20.7682 15.4109 19.845 12.6928C19.6873 12.0534 18.7247 12.1738 18.7295 12.8406C20.8341 18.8003 16.5151 25.2936 10.2014 25.2486C5.33008 25.3503 1.03409 20.9984 1.13525 16.0616C1.04177 9.78748 7.50999 5.22164 13.2949 7.42601C13.9955 7.67595 14.3783 6.61299 13.6811 6.3465C7.17492 3.86675 -0.104575 9.00358 0.00194884 16.0618C-0.111216 21.615 4.7205 26.5121 10.2015 26.397ZM17.4133 23.3691C17.8745 22.9017 18.2805 22.3917 18.6384 21.8535L23.1414 24.5101C25.0218 25.5511 25.3814 28.3744 23.8253 29.8668C22.3518 31.4386 19.5624 31.0806 18.5391 29.1742C18.5391 29.1743 15.921 24.6173 15.921 24.6173C16.45 24.2525 16.9508 23.8377 17.4133 23.3691Z" fill="white" />
              <path d="M12.7268 10.3394C13.3853 10.656 13.8597 9.64746 13.2087 9.31009C10.4566 7.93789 7.15092 8.51503 4.98163 10.7471C0.459859 15.4055 3.74415 23.4778 10.0762 23.429C15.823 23.4941 19.3619 16.5799 15.9559 11.7115C15.5327 11.1044 14.6369 11.7609 15.0527 12.3731C16.773 14.8755 16.4931 18.2733 14.3879 20.4512C12.0101 22.8988 8.14212 22.8982 5.76594 20.4524C1.0157 15.3614 6.51647 7.37911 12.7268 10.3394Z" fill="white" />
              <path d="M9.42275 16.4414C9.66463 16.6352 10.0288 16.608 10.2351 16.3769L15.137 10.8923C15.9036 11.2758 16.9527 11.1413 17.5905 10.6108L20.8227 12.8351C20.4675 13.8539 21.3426 14.952 22.4726 14.9339C23.7366 14.965 24.6202 13.5858 23.9998 12.5472L29.9639 6.4998V8.38848C29.9757 9.10369 31.1038 9.10675 31.1164 8.38845V5.11577C31.1038 4.81547 30.8534 4.58313 30.5401 4.57031H27.0826C26.3268 4.5821 26.3238 5.64893 27.0826 5.66122C27.0826 5.66122 29.2135 5.66122 29.2135 5.66122L23.161 11.7983C22.6412 11.5698 21.9521 11.6372 21.4955 11.9497L18.2738 9.73264C18.8929 8.35056 17.7243 6.72043 16.1338 6.75212C14.3105 6.69902 13.1632 8.82847 14.255 10.1897L9.35494 15.6727C9.14954 15.9023 9.17992 16.2469 9.42275 16.4414ZM22.4726 12.7521C23.2299 12.7648 23.2298 13.8304 22.4725 13.843C21.7153 13.8302 21.7154 12.7647 22.4726 12.7521ZM16.1338 7.84302C17.0498 7.81605 17.6227 8.89045 17.0668 9.5692C16.4487 10.4213 14.9612 9.97666 14.9813 8.93389C14.9813 8.33227 15.4982 7.84302 16.1338 7.84302Z" fill="white" />
              <path d="M30.6091 0H9.72289C7.85522 0 6.33594 1.50133 6.33594 3.34694V5.54473C6.34634 6.27875 7.45779 6.27217 7.46492 5.5447V3.34694C7.46492 2.11662 8.47787 1.11565 9.72289 1.11565H30.6091C31.8544 1.11565 32.8671 2.11662 32.8671 3.34694V20.0816C32.8671 21.3122 31.8544 22.3129 30.6091 22.3129H24.9306C24.6186 22.3129 24.3661 22.5624 24.3661 22.8708C24.3661 23.1791 24.6186 23.4286 24.9306 23.4286H30.6091C32.4768 23.4286 33.9961 21.9272 33.9961 20.0816V3.34694C33.9961 1.50133 32.4768 0 30.6091 0Z" fill="white" />
              <path d="M24.7724 19.4277C24.0151 19.4396 24.0117 20.5581 24.7724 20.5706H31.1252C31.8824 20.5588 31.8859 19.4403 31.1252 19.4277H24.7724Z" fill="white" />
              <path d="M31.1259 17.1426H27.656C26.8968 17.1547 26.895 18.273 27.656 18.2854H31.1259C31.8851 18.2733 31.8869 17.155 31.1259 17.1426Z" fill="white" />
              <path d="M13.3143 3.9993C13.9967 3.98695 13.9989 2.86925 13.3143 2.85645H9.15325C8.47084 2.86879 8.46865 3.98647 9.15328 3.9993H13.3143Z" fill="white" />
            </svg></span>
            <span className="">{t('Our formulas')}</span></button>
        </a> */}
      </Title>
      <div className="Equipe-cm-container">
        <div className="respect-container">
          <div className="respect-icon">
            <img src={img} alt="Réactivité" />
          </div>
          <div className="respect-content">
            <h3>Notre équipe élabore, anime et modère pour vous les réseaux sociaux de votre marque</h3>
            <p className="title-p">Pour accroître votre visibilité et votre notoriété. Choisissez l’offre qui vous convient le mieux et contactez un membre de notre équipe pour démarrer simplement et rapidement. Un community manager prendra alors contact avec vous pour identifier vos besoins, fixer vos objectifs et établir les leviers adéquats de votre stratégie social media.</p>
          </div>
        </div>
        <div className="services-section-media">
          {servicesCm.map((service) => (
            <div key={service.id} className="service-card-media">
              <img src={service.icon} alt={`Icon ${service.id}`} className="service-media-icon" />
              <h3>{service.title}</h3>
              <ul>
                {service.points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="why-manager">
        <div className="respect-container">
          <div className="respect-icon">
            <img src={img} alt="Réactivité" />
          </div>
          <div className="respect-content">
            <h3>Pourquoi travailler avec un community manager?</h3>
            <ul>
              <li><p className="title-p">Parce qu’une présence active sur les réseaux sociaux est essentielle pour bâtir une image forte et crédible.</p></li>
              <li><p className="title-p">Parce que les réseaux sont une plateforme puissante pour accroître votre visibilité et dialoguer directement avec vos clients.</p></li>
              <li><p className="title-p">Parce que chaque interaction est une chance unique de créer de la valeur, fidéliser votre audience, et ajuster vos offres aux attentes réelles de vos clients.</p></li>
            </ul>
            <p className="why-p">Il existe de nombreuses astuces pour être visible sur internet et nous vous orienterons sur celles qui seront les plus adaptées à votre marque:</p>

          </div>
        </div>
        <div className="social-media-actions">
          {visibleActions.map((action) => (
            <div key={action.id} className="action-card">
              <img src={action.icon} />
              <p>{action.text}</p>
            </div>
          ))}
        </div>
        {isSmallScreen && actions.length > 5 && (
          <button
            className="show-more-btn"
            onClick={() => setShowAll((prev) => !prev)}
          >
            {showAll ? "Voir moins" : "Voir plus"}
          </button>
        )}

      </div>
      <div className="other-services-media-page">
        <div className="respect-container">
          <div className="respect-content">
            <h3>Autres services</h3>
          </div>
        </div>
        <div className="other-cards-media-page">

          <a href="/fr/service-de-consultation" className="other-card-media">
            <img src={consulting} alt="consulting-icon" />
            <h3>Consulting</h3>
            <p className="title-p">
              Besoin de conseils pointus et avisés sur votre business digital ? Nos experts en consulting vous répondent.
            </p>
          </a>

          <a href="/fr/prestation-de-service" className="other-card-media">
            <img src={prestation} alt="prestation-icon" />
            <h3>Sous traitance</h3>
            <p className="title-p">Vous avez des besoins techniques, de développement ou digitaux? Vous êtes au bon endroit!</p>
          </a>
        </div>
      
            <StratégieSocialMedia id='section'>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Strategy')} {t('social media')}</h2>
        <p className="title-p">{t('Cm.Opt for one of the packages')}</p>

        <div className="ListButton">
          <button className={active === 1 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActive(1); setPresence(300); setVisibilite(580); setPerformance(1190) }}>{t('WITHOUT COMMITMENT')}</button>
          <button className={active === 2 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActive(2); setPresence(260); setVisibilite(540); setPerformance(1099) }}>{t('SUBSCRIPTION')} 6 {t('MONTH')}</button>
          <button className={active === 3 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActive(3); setPresence(230); setVisibilite(499); setPerformance(999) }}>{t('SUBSCRIPTION')} 12 {t('MONTH')}</button>
        </div>

        <div className="row gy-3">
          <div className="col-md-4">
            <div className="card">
              <ul className={langue === "ar" && "ul-ar"}>
                <div>
                  <span className="euroextendedbold">{t('Presence Pack')}</span><br />
                  <span className="number"> {presence} <sup>€</sup></span> / {t('Month')}
                </div>
                <li><h3>{t('Starting')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Kick-off_meeting')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Definition of objectives')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Update of the page')}</span></li>
                <hr />
                <li><h3>{t('Strategy')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Shared working document')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Quarterly strategic review')}</span></li>
                <hr />
                <li><h3>{t('Social media')}</h3></li>
                <li>{t('Publication on')} 1 {t('social media')}</li>
                <li>1 {t('publication per week')}</li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Content writing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Creation of visuals')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Photos with rights of use')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Choice of hashtags')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Integration of publications')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Programming & publishing')}</span></li>
              </ul>
              <div>
                <p>{t('Set_up')}<span>300 €</span></p>
                 {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button>  */}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <ul className={langue === "ar" && "ul-ar"}>
                <div>
                  <span className="euroextendedbold">{t('Visibility Pack')}</span><br />
                  <span className="number"> {visibilite} <sup>€</sup></span> / {t('Month')}
                </div>
                <li><h3>{t('Starting')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Kick-off_meeting')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Definition of objectives')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Update of the page')}</span></li>
                <hr />
                <li><h3>{t('Strategy')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Shared working document')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Quarterly strategic review')}</span></li>
                <hr />
                <li><h3>{t('Social media')}</h3> </li>
                <li>{t('Publication on')} 2 {t('social media')}</li>
                <li>2 {t('publication per week')}</li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Content writing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Creation of visuals')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Photos with rights of use')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Choice of hashtags')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Integration of publications')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Programming & publishing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Organization of a contest')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Animation and moderation')} 4{t('h')}/{t('Month')}</span></li>
              </ul>
              <div>
                <p>{t('Set_up')}<span>300 €</span></p>
                 {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button>  */}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <ul className={langue === "ar" && "ul-ar"}>
                <div>
                  <span className="euroextendedbold">{t('Performance Pack')}</span><br />
                  <span className="number"> {performance} <sup>€</sup></span> / {t('Month')}
                </div>
                <li><h3>{t('Starting')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Kick-off_meeting')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Definition of objectives')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Update of the page')}</span></li>
                <hr />
                <li><h3>{t('Strategy')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Shared working document')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Quarterly strategic review')}</span></li>
                <hr />
                <li><h3>{t('Social media')}</h3> </li>
                <li>{t('Publication on')} 4 {t('social media')}</li>
                <li>4 {t('publication per week')}</li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Content writing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Creation of visuals')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Photos with rights of use')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Choice of hashtags')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Integration of publications')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Programming & publishing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Organization of a contest')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Animation and moderation')} 6{t('h')}/{t('Month')}</span></li>
              </ul>
              <div>
                <p>{t('Set_up')}<span>300 €</span></p>
              </div>
            </div>
          </div>
        </div>
      </StratégieSocialMedia> 
      <div className="meet-rec-cm">
          <div className="respect-container">
            <div className="respect-content">
              <h3>Vous êtes prêt à lancer votre campagne web marketing?</h3>
            </div>
          </div>
          <div className="detail-meet-rec">
            <div className="meet-rec-title-btn">
              <p className="title-p">Contactez-nous dès aujourd’hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à atteindre vos objectifs !</p>
              <MyButton />
            </div>
          </div>
        </div>
      </div>
    
    </div>
  </>)
}


const StratégieSocialMedia = styled.div`
  margin-top: 70px;
  @media (max-width: 991px) {
   margin-top: 50px;
  }
  @media (max-width: 767px) {
   margin-top: 45px;
  }
   @media (max-width: 575px) {
      margin-top: 35px;
   }
  >h2{

    padding-bottom: 10px;
  }

  >p{

    padding-bottom: 20px;
  }

  >.ListButton{
    background-color: #20ACE3;
    margin-bottom: 40px ;
    border-radius: 6px;
    @media (max-width: 577px) {
      display: flex;
      flex-direction: column;
    }
    >.btn-light{
      width: 33.33%;
      color: white;
      background-color: transparent;
      border: 0;
      @media (max-width: 577px) {
      width: 100%;
      }
    }
    >.active{
      background-color: white;
      color: #20ACE3;
      border: 4px solid #20ACE3;
    }
    >.btn-light.active:focus{
      box-shadow: none;
    }
    >.btn-light:focus-visible{
      outline: none;
      border: transparent;
    }
    >.btn-light:focus{
      box-shadow: none;
      border: 4px solid #20ACE3;
    }
    .btn-light:active{
      border: 4px solid #20ACE3;
    }

  }

  >.row {
    justify-content: center;
    .col-md-4 {
      @media (max-width: 1051px) {
      width: 50%;
    }
    @media (max-width: 663px) {
      width: 100%;
    }
    justify-content: center;
    
    .card{
      box-shadow: 0px 0px 6px rgba(32, 172, 227, .16);
      padding: 30px;
      height: 100%;
      border-radius: 15px;
      >ul{
        list-style: none;
        padding-left: 0;
        >li{
          line-height: 28px;
    font-size: 14px;
    gap: 12px;
    display: flex;
          >h3{
           font-family: RalewayRegular;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 28px !important;
    font-size: 14px !important;
          }
          >.blue{
            color :#20ACE3;
          }
          >img{
            padding-right: 8px ;
          }
        }
        >div{
          background-color: #EEF7FB;
          padding-top: 20px;
          margin-bottom: 20px;
          border-radius: 15px;
          font-weight: 600;
          color :#20ACE3;
          text-align: center;
          >.euroextendedbold{
            color: #17406F;
            font-family: 'RalewayRegular';
          }
          .number{
            font-size: 42px;
            font-family: 'RalewayRegular';
            >sup{
              font-size: 16px;
              position: relative;
              top: -25px;
              margin-left: -12px;
            }
          }
        }
      }
      >.ul-ar{
        padding-right: 0;
      }
      >ul >li >img{
        padding-right: 0;
        padding-left: 8px;
      }
      >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        place-content: flex-end;
        height: 100%;

        >p{
        padding: 12px 19px;
    text-align: center;
    border-radius: 6px;
    color: rgb(23, 64, 111);
    display: flex;
    flex-flow: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
    background-color: rgb(238, 247, 251);
    font-weight: bold;
    font-family: RalewayRegular;
    margin-bottom: 0px;
        
        } 
        >.button{
          padding: 10px 20px !important;
          color: white;
          width: 150px;
          border-radius:10px !important;
        }
      }
    }
  }
}
`;

export default withTranslation()(Cm)