import React from 'react';
import './BlogCard.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const BlogCard = ({ blog }) => {
  return (
    <div className="blog-card">
      <img src={blog.imageUrl} alt={blog.title} className="blog-image" />
      <div className="blog-content">
        <p className="blog-date">{blog.date}</p>
        <h3 className="blog-title" dangerouslySetInnerHTML={{ __html: blog.title }} />

        <p className="blog-description">{blog.description}</p>
        <span className='d-flex align-items-center gap-2'>

          <Link
            to={{
              pathname: blog.link.pathname,
              state: blog.link.state 
            }}
            className="d-flex align-items-center gap-2"
          >
           <span className="link-blog-page">Lire l'article</span> <span className="arrow-blog">❯</span>
          </Link>
          
        </span>
      </div>
    </div>
  );
};

export default BlogCard;
