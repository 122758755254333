import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { Breadcrumb, Title } from "../../../css/commun";
import Cf7FormWrapper from "./Cf7FormWrapper";
import { useForm } from "react-hook-form";
import { URL_Back, URL_Dev } from "../Variables";
import Maps from "../../../Model/Maps";
import Pages from "../../../Model/Pages";
import Contacter from "../../../Model/Contacter";
import { Helmet } from "react-helmet-async";
import Popup from "../../Popup";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './contact.css'
import TestimonialCarousel from "../TestimonialCarousel";
import MapComponent from "./map/MapComponent";
const SubContact = (props) => {

  const title = props.subprops.location.pathname
  const splitState = title.split('/')
  const staticTitles = {
    en: "Contactez-nous",
    fr: "Contactez-nous"
  };
  const { t } = props
  let handler = props.handler;
  let hasError = props.haserror;
  let isLoading = props.isloading;
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState([]);
  const [contacter, setContacter] = useState([]);
  const [existError, setExistError] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [voirPlus, setVoirPlus] = useState(true);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);
  const [avis, setAvis] = useState();

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${URL_Back}/wp-json/reviews/v1/all`);
        const result = await response.json();
        setAvis(result);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const map = [
    {
      name: 'Oxton Digital Paris',
      lat: 48.9824443,
      lng: 2.2713421,
      address: '214 rue du Général Leclerc, 95120 ERMONT',
    },
    {
      name: 'Oxton Digital Tunis',
      lat: 36.45878601074219,
      lng: 10.746866226196289,
      address: '15 avenue Habib Bourguiba 8070 Nabeul, Tunisie',
    }]

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);


  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
    fetch(`${URL_Back}/wp-json/wp/v2/maps?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/contacter?_embed`)
      .then(res => res.json())
      .then(
        (result) => {
          setContacter(result)
        },
      )

  }, [])

  let maps = [];
  items.map(element =>
    maps.push(new Maps(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered))
  )

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description,element.yoast_head_json.title, element.date, element.excerpt.rendered)))



  let contacterEmail = []
  let contacterTel = []
  contacter.map(element =>
    element.title.rendered === "Email" &&
    contacterEmail.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element.image)))
  contacter.map(element =>
    element.title.rendered === "Telephone" &&
    contacterTel.push(new Contacter(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
  )

  const initialValues = { yourname: '', youremail: '', yoursubject: '' };
  const [formState, setFormState] = useState(initialValues)
  const letters = /^[A-Za-z0-9._,.:;*$"'#?!^[{}()\]\\\-\+=@é àçèâêîû/ô._\s-]+$/;
  const validEmail = /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const { formState: { errors } } = useForm();

  const handleFieldChange = (field, e) => {
    !errors.yourname && (errors.yourname = {})
    !errors.yoursubject && (errors.yoursubject = {})
    !errors.youremail && (errors.youremail = {})

    setFormState({
      ...formState,
      [field]: e.target.value,
    })

    if (field === "yourname") {
      if (e.target.value.length < 2) {
        errors.yourname.message = "La longueur doit être de 2 ou plus"
        setExistError(false)
      } else if (!e.target.value.match(letters)) {
        errors.yourname.message = "Veuillez saisir uniquement des lettres"
        setExistError(false)
      } else {
        errors.yourname = {}
      }
    }

    if (field === "yoursubject") {
      if (e.target.value.length < 5) {
        errors.yoursubject.message = "La longueur doit être de 5 ou plus"
        setExistError(false)
      } else {
        errors.yoursubject = {}
      }
    }

    if (field === "youremail") {
      if (e.target.value.length < 5) {
        errors.youremail.message = "La longueur doit être de 5 ou plus"
        setExistError(false)
      } else if (!validEmail.test(e.target.value)) {
        errors.youremail.message = "L’adresse e-mail n’est pas valide."
        setExistError(false)
      } else {
        errors.youremail = {}
      }
    }
  }


  const handleFormSubmit = (e) => {
    e.preventDefault();

    !errors.yourname && (errors.yourname = {})
    !errors.yoursubject && (errors.yoursubject = {})
    !errors.youremail && (errors.youremail = {})

    var verifError = true;

    if (!formState.yourname) {
      errors.yourname.message = "Ce champ est Obligatoire";
      verifError = false
    } else if (formState.yourname.length < 2) {
      errors.yourname.message = "La longueur doit être de 2 ou plus"
      verifError = false
    }

    if (!formState.yoursubject) {
      errors.yoursubject.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.yoursubject.length < 5) {
      errors.yoursubject.message = "La longueur doit être de 5 ou plus"
      verifError = false
    } else if (!formState.yoursubject.match(letters)) {
      errors.yoursubject.message = "Veuillez saisir uniquement des lettres"
      verifError = false
    }

    if (!formState.youremail) {
      errors.youremail.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.youremail.length < 5) {
      errors.youremail.message = "La longueur doit être de 5 ou plus"
      verifError = false
    } else if (!validEmail.test(formState.youremail)) {
      errors.youremail.message = "L’adresse e-mail n’est pas valide."
      verifError = false
    }

    verifError && handler(e, formState)
    verifError && e.target.reset()
    setExistError(verifError);
  }

  console.log("page", page)

  return (
    <>
      <Helmet>
        <title>{page[0] !== undefined && page[0].metatitle}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container" >
        <Breadcrumb>
          <p>
            <a className="retour" href="/">{t('Home_link')}</a> / <span>{titre}</span>
          </p>
        </Breadcrumb>
        <Title>
          <h1>{titre}</h1>

          <p className="title-p">Un projet à nous faire part ? Nous avons de vous rencontrer. Révélez nous vos besoin & vos envies !</p>
        </Title>
        <RecAudit>
          <h3> Demander votre audit gratuit</h3>
          <div className="text-btn-audit d-flex align-items-center justify-content-between">
            <p className="title-p contentp">L’équipe OXTON vous accompagne dans l’analyse de votre marketing pour isoler et structurer vos enjeux et stratégies marketing.</p>
            <button type="submit" className="btn btn-primary button auditb mb-0 submit"
              onClick={() => window.open('https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/s/fBOxIZOHg0Wi5oT3BGJoaQ2', '_blank')}
            >
              <span className="pseudo-textf">Profiter de 30mn d’audit offertes</span></button>
          </div>
        </RecAudit>

        <Form >
        <h3 className={langue === "ar" ? "alignRightMobile" : undefined}>Vous avez un projet en tête ?</h3>
          {/* <p className={langue === "ar" && "alignRightMobile"}>
          {t('Contact.start')} &nbsp;
          <span className={voirPlus ? "hide" : ""}>
            &nbsp;{t('Contact.looking')}
          </span>
          <span onClick={() => voirPlus ? setVoirPlus(false) : setVoirPlus(true)} className="pseudo-text">{voirPlus ? t('more') : t('less')}</span>
        </p> */}
          <form className="from" onSubmit={handleFormSubmit} method="POST">
            <FormControl className={langue === "ar" ? "inputleft inputleft-ar" : "inputleft"}>
              <InputLabel
                className={langue === "ar" ? "label-ar" : undefined}
                style={{ color: '#000', marginTop: "-10px" }}
              >{t('Contact.name')}</InputLabel>
              <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yourname", e)} />
              {errors.yourname && (<small className="text-danger">{errors.yourname.message}</small>)}
            </FormControl>
            <FormControl className={langue === "ar" ? "inputRight inputRight-ar" : "inputRight"}>
              <InputLabel
                className={langue === "ar" ? "label-ar" : undefined}
                style={{ color: '#000', marginTop: "-10px" }}
              >{t('Contact.Email')}</InputLabel>
              <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("youremail", e)} autoComplete="off" />
              {errors.youremail && (<small className="text-danger">{errors.youremail.message}</small>)}
            </FormControl>
            <div className="Project">
              <FormControl fullWidth className={langue === "ar" ? "fullWidth fullWidth-ar" : "fullWidth"}>
                <InputLabel
                  className={langue === "ar" ? "label-ar" : undefined}
                  style={{ color: '#000', marginTop: "-10px" }}
                >{t('Contact.project')}</InputLabel>
                <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yoursubject", e)} autoComplete="off" />
                {errors.yoursubject && (<small className="text-danger">{errors.yoursubject.message}</small>)}
              </FormControl>
            </div>
            <button type="submit" className={langue === "ar" ? "btn btn-primary button button-ar submit" : "btn btn-primary button submit"} onClick={() => setButtonPopup(true)}><span className="pseudo-textf">{t('Contact.Send')}</span></button>
            {existError && !isLoading && (
              <Popup trigger={buttonPopup} setTrigger={setButtonPopup} onBlur={() => setButtonPopup(false)}>
                <p>{hasError ? "Réessayez plus tard" : t('Contact.Thank')}</p>
              </Popup>
            )}
            {/* <Link to={langue === "fr" ? "/fr/devis" : langue === "en" ?  "/en/quotation" : "/ar/التسعير"} className="links">
          Demander un devis
          </Link> */}
          </form>
        </Form>
        <Bureau>
          <h1>Nos bureaux</h1>
          <p className="title-p">Vous trouverez ci-dessous toutes informations pour nous contacter</p>
        </Bureau>
        <Details>
          <div className="notre-bureau">
            <img className="img-bureau" src="/images/Contact/espace-reserve-a-la-carte (1).png" alt="espace-reserve-a-la-carte" />
            <div className="d-flex  flex-column gap-2">
              <span className="c-title">{t('Contact.paris')}</span>
              <span>{t('Contact.location_paris')} </span>
              <span>{t('Contact.location_paris2')}</span>
            </div>

          </div>
          <div className="notre-bureau">

            <img className="img-bureau" src="/images/Contact/send.png" alt="send" />
            <div className="d-flex  flex-column gap-2">
              <span className="c-title">E-mail</span>
              {contacterEmail.map(item => (
                <a key={item.id} className="aLink" href={`mailto:${item.content.slice(4, -5)}`}>{item.content.slice(4, -5)}<br /></a>
              ))}
            </div>
          </div>


          <div className="notre-bureau">
            <img className="img-bureau" src="/images/Contact/espace-reserve-a-la-carte (1).png" alt="espace-reserve-a-la-carte" /><br />
            <div className="d-flex  flex-column gap-2">
              <span className="c-title">{t('Contact.tunis')}</span>
              <span>15 avenue Habib Bourguiba 8070 Nabeul, Tunisie</span>
            </div>
          </div>

          <div className="listOfDrapo notre-bureau">
            <img className="img-bureau" src="/images/Contact/telephone (2).png" alt="telephone" />
            <div className="d-flex  flex-column gap-2">
              <span className="c-title">Nos numéros</span>
              <div className="d-flex align-items-center drapolist">
                {contacterTel.reduce((result, item, index) => {
                  if (index % 2 === 0) {
                    result.push([item]);
                  } else {
                    result[result.length - 1].push(item);
                  }
                  return result;
                }, []).map((pair, i) => (
                  <ul key={i}>
                    {pair.map((item, key) => (
                      <li key={key}>
                        <img className="drapo" src={item.image} alt={item.title} />
                        <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}><bdi>{item.content.slice(4, -5)}</bdi></a>
                      </li>
                    ))}
                  </ul>

                ))}
              </div>
            </div>
          </div>
        </Details>


        <Map>

          <MapComponent locations={map} />
          {/* {maps.map((item, key) => (
            <div className="mapouter" key={key}>
              <div className="gmap_canvas maps" dangerouslySetInnerHTML={{ __html: item.content }} />
              <br />
            </div>
          ))} */}
        </Map>

        <div className="voix-contact-page">
          <div className=" voix">
            <h2>Voix de nos clients</h2>
            <p className="title-p">Découvrez ce que les clients pensent de nous. Promis, ce sont des vrais clients contents,
              allez voir sur Google !</p>
            <TestimonialCarousel testimonials={avis} />
          </div>
        </div>

      </div>
    </>)
}

const Bureau = styled.div`
 margin-top: 70px;
 @media(max-width: 991px){
   margin-top: 60px;
 }
 @media(max-width: 575px){
   padding: 0px 20px !important ;
   margin-top: 40px;
  }
  h1{
  font-weight: 600;
    font-size: 42px !important;
    font-family: RalewayRegular !important;
    color:#20ACE3 ;
    @media(max-width: 991px){
       font-size: 32px !important;
    }
    @media (max-width: 576px) {
      font-size: 24px !important;
      line-height: 36px !important;
     }
    }
   }
  
`
const RecAudit = styled.div`
 border: 1px solid #17406F;
  padding: 30px;
  border-radius: 6px;
  text-align: left;
  margin: 20px 0;
  @media (max-width: 575px){
    margin: 0px 20px 0px !important;
  }

  h3{
  font-weight:700 !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  }
  
  .button.btn.btn-primary.button.submit {
    color: #FFF;
    background-color: #17406F;
    font-size: 16px;
}

    .submit {
    padding: 13px 30px !important;
    }
  @media (max-width: 991px) {
    margin: 0px 0px;
    padding: 20px;
    h3 {
      font-size: 24px !important;
    }
    p {
      font-size: 16px;
    }
    .text-btn-audit{
        align-items: flex-start !important;
        gap: 18px;
      
      }
    }
     @media (max-width: 575px) {
        h3 {
        font-size: 20px !important;
    }
     }
  }
`;
const Map = styled.div`
width: 100vw;
    margin-left: calc(-50vw + 50%);
margin-top:90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
    @media (max-width:991px) {
      flex-direction: column;
    }
     @media (max-width: 575px){
       margin-top:40px;
     }  
    >div{
    width: 100%;
    @media (max-width:991px) {
      width: 100%;
      >.mapFrance{
        margin-top: 30px;
      }
    }
  }

  
 
`;

const Details = styled.div`
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 20px;
    justify-items: start;
    row-gap: 6rem;
    column-gap: 2rem;
    @media(max-width: 991px){
      gap: 2rem 2rem ;
    }
      @media(max-width: 767px){
      grid-template-columns: repeat(1, 2fr);
      margin-top: 35px;

    }
    @media(max-width: 575px){
      margin: 20px 20px 0px 20px !important;
    }
  .c-title{
    font-size: 20px;
    font-weight: 600;
  }
  .listOfDrapo ul{
    list-style-type: none;
    display: inline-block;
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  gap:10px ;
  list-style: none; 
  padding: 0;
  margin: 0;
    >li{
      text-align: initial !important;
      gap: 8px;
      display: flex;
      align-items: center;
      
    }
  @media (max-width: 1000px){
    flex-direction: column;
    justify-content: center;
    }
  @media (max-width: 991px){
    gap: 2rem 2rem;

  }
  

  }
  >div{
    
    line-height: 30px;
    display: flex;
    align-items: flex-start;

    @media (max-width: 1000px){
    width: 100% !important;
    }
    &:nth-child(2){
  
    >span{
      color: #20ACE3;
    }
    } 
    >img{
        padding: 0px 30px;
      @media (max-width: 991px){
        padding: 0px 0px;
        img{
         width:52px ;
        }
      }
    }
  }
`;

const Form = styled.div`
  margin-top: 65px;

  @media (max-width: 991px){
    padding:0px 0%;
    margin-top: 60px;
  }
  @media (max-width: 575px){
    padding: 0px 20px ;
    margin-top: 40px;
  }

  h3{
  font-size: 32px !important;
    line-height: 50px !important;
     @media (max-width: 991px){
      font-size: 24px !important;
     }
     @media (max-width: 575px) {
      font-size: 20px !important;
     }
  }
  >p {
    text-align: center;
    @media (max-width: 991px) {
      text-align: left;
    }
  }

  >p >span.pseudo-text{
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
  }
   .pseudo-textf {
    display: inline;
    position: relative;
    font-weight: 500;
    transition: .25s ease-in;
    transition-delay: .1s;
}
  .inputleft{
    width: 47%;
    margin-right: 3%;
    @media (max-width:676px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .inputleft-ar{
    margin-right: 0;
    margin-left: 0%;
  }

  .inputRight{
    width: 50%;
    margin-left: 4%;
    @media (max-width:676px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .inputRight{
    margin-right: 0%;
    margin-left: 0;
  }
    .fullWidth{
    margin: 0 2% 0 0;
  }
  .fullWidth-ar{
    margin: 0 0 0 2%;
  }
  .hide{
    display: none;
  }

  >.from{
    line-height: 100px;
    margin-top: 40px;
     @media (max-width: 575px){
      margin-top: 20px;
      line-height: 83px;

     }
  }
  .input{
    border: 0;
    border-bottom:1px solid #17406F;
    background-color: transparent;
    outline: none;
    color: #17406F;
  }
    label{
    font-family: InterRegular !important;
    font-size: 18px !important;
    font-weight: 500;
    @media (max-width: 991px){
      font-size: 16px !important;
     }
      @media (max-width: 575px){
      font-size: 14px !important;
     }
    }

  input:-webkit-autofill,
  .my-class:-webkit-autofill {
    transition-delay: 9999999s;
  }

  .submit{
    display: block;
    margin-left: auto;
    background-color: #17406F;
    color: #fff;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 10px 62px !important;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 575px){
      font-size: 14px !important;
    }
    
  }

  .text-danger{
    position: absolute;
    height: 0px;
    top:15px;
    font-size: 16px;
    font-weight: 600;
    color: #c51414b3 !important;
  }

  >form span.merci{
    display: flex;
    place-content: center;
    color: #036203;
    z-index:9999;
  }
`;

export default withTranslation()(SubContact);
