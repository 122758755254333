import React, { useState, useEffect } from "react";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './ServicesMobile.css'
import mobile1 from "../../../photos/mobile/mobile1.png"
import mobile2 from "../../../photos/mobile/mobile2.png"
import mobile3 from "../../../photos/mobile/mobile3.png"
import FrequentQuestions from "./FrequentQuestions";
import { Link } from 'react-router-dom';
import { questionsMobile, servicesMobiles, cardsMobile } from "../../Data.js"

const ServicesMobile = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/');
  const staticTitles = {
    en: "Développement Mobile",
    fr: "Développement Mobile"
  };
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);


  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description,element.yoast_head_json.title, element.date, element.excerpt.rendered)))



  return (
    <>
      <Helmet>
        <title>{page[0] !== undefined && page[0].metatitle}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container" >
        <Breadcrumb>
          <p>
            <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
          </p>
        </Breadcrumb>
        <Title>
          <h1>{titre}</h1>

        </Title>

        <div className="cards-images-mobile-dev-page">
          <div className="rec-image-mobile rec-im-1">
            <img src={mobile3} className="img-fluid" alt="image-mobile1" />
          </div>
          <div className="rec-image-mobile rec-im-2">
            <img src={mobile2} className="img-fluid" alt="image-mobile2" />
          </div>
          <div className="rec-image-mobile rec-im-3">
            <img src={mobile1} className="img-fluid" alt="image-mobile3" />
          </div>
        </div>
        <div className="importance-app-mobile-container">
          <div className="respect-container">
            <div className="respect-content">
              <h3>L'Importance des Applications Mobiles en 2024 : Investir pour l'Avenir </h3>
              <p className="title-p">En 2024, le paysage numérique continue d'évoluer à une vitesse fulgurante, et les applications mobiles occupent une place centrale dans cette transformation. Voici quelques statistiques marquantes qui soulignent l'importance d'investir dans une application mobile pour votre entreprise :</p>
            </div>
          </div>
          <div className="mobile-services">
            <div>
              {servicesMobiles.map((service, index) => {
                const isLast = index === servicesMobiles.length - 1;
                if (isLast) {
                  return (
                    <div key={service.id} className="mobile last-mobile">
                      <div>

                        <div className="d-flex align-items-center gap-3 mobile-img-title">
                          <img src={service.image} alt={service.title} />
                          <h5>{service.title}</h5>
                        </div>
                        <p className="title-p">{service.description}</p>
                      </div>
                    </div>
                  );
                }
                return (
                  index % 2 === 0 && (
                    <div key={service.id} className="d-flex mobile">
                      <div>
                        <div className="d-flex align-items-center gap-3 mobile-img-title">
                          <img src={service.image} alt={service.title} />
                          <h5>{service.title}</h5>
                        </div>
                        <p className="title-p">{service.description}</p>
                      </div>
                      {servicesMobiles[index + 1] && (
                        <div>
                          <div className="d-flex align-items-center gap-3 mobile-img-title">
                            <img src={servicesMobiles[index + 1].image} alt={servicesMobiles[index + 1].title} />
                            <h5>{servicesMobiles[index + 1].title}</h5>
                          </div>
                          <p className="title-p">{servicesMobiles[index + 1].description}</p>
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
        <div className="mobile-choisir-oxton">
          <div className="respect-container">
            <div className="respect-content">
              <h3>Choisir l’excellence pour le développement d’applications mobile : OXTON DIGITAL !</h3>
              <p className="title-p">Pour bien choisir votre partenaire pour la création d’une application mobile, voici les critères qui font de notre agence le choix idéal : </p>
            </div>
          </div>
          <div className="mobile-cards">
            {cardsMobile.map((card) => (
              <div key={card.id} className="mobile-card">
                <div className="mobile-card-icon">
                  <img src={card.icon} alt={`Icon ${card.id}`} />
                </div>
                <h3 className="mobile-card-title">{card.title}</h3>
                <p className="mobile-card-text">{card.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="questions-mobile">
          <div className="respect-container">
            <div className="respect-content ">
              <h3>FAQ sur le Développement d'Applications Mobiles </h3>
            </div>
          </div>
          <FrequentQuestions questions={questionsMobile} />
        </div>
      </div>
    </>
  )
}



export default withTranslation()(ServicesMobile)