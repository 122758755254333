import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Portfolio from "../../../Model/Portfolio";
import styled from "styled-components";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import { ListPortfolio } from "./ListPortfolio";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie"; import { TailSpin } from 'react-loader-spinner';
import { Table } from "./Portfolio";
const Portfolios = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')

  const { t } = props

  const [items, setItems] = useState([]);
  const [itemsFiltred, setItemsFiltred] = useState([]);
  const [filterLabel, setFilterLabel] = useState('')
  const [number, setNumber] = useState(12)
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [loading, setLoading] = useState('true')

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const scrollToEnd = () => {
    setNumber(number + 3)
  }

  window.onscroll = function () {
    if (window.innerHeight + document.documentElement.scrollTop > (document.documentElement.offsetHeight - 300)) {
      scrollToEnd()
    }
  }

  const extractDevContent = (content) => {
    const container = document.createElement('div');
    container.innerHTML = content;
    const devDiv = container.querySelector('.dev');
    return devDiv ? devDiv.innerHTML : null;
  };


  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )

    let filterLabel = splitState[1].length === 2 ? splitState[3] : splitState[2];
    if (filterLabel === undefined) {
      filterLabel = localStorage.getItem('filterLabel');
    }

    var lng = currentLanguage
    lng === undefined && localStorage.getItem("lng")
    setFilterLabel(filterLabel)
    setLangue(currentLanguage)
    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
    .then((res) => res.json())
    .then((result) => {
      const filteredItems = result.filter((item) => {
        // Vérification de la langue
        const isLangMatch =
          item.excerpt.rendered?.trim() ===
          (lng === "fr" ? "<p>French</p>" : "<p>English</p>");
  
        const isContentMatch =
          filterLabel &&
          Array.isArray(item.techo_choice_content) &&
          item.techo_choice_content.some((tech) =>
            tech.name.toUpperCase().includes(filterLabel.toUpperCase())
          );

        const isDesignMatch =
          filterLabel &&
          filterLabel.toUpperCase().includes("DESIGN") &&
          Array.isArray(item.categoriesportfolio) &&
          item.categoriesportfolio.includes(7);
        const isEcommerceMatch =
          filterLabel &&
          filterLabel.toUpperCase().includes("E-COMMERCE") &&
          Array.isArray(item.categoriesportfolio) &&
          item.categoriesportfolio.includes(254); 
  
        return isLangMatch && (isContentMatch || isDesignMatch || isEcommerceMatch);
      });
      setItemsFiltred(filteredItems);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Erreur lors de la récupération des données :", error);
    });
  
  }, [])

  console.log(itemsFiltred)
  let portfolio = [];
  itemsFiltred.map(element => element._embedded['wp:featuredmedia'] ?
    portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
    : portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element.excerpt.rendered))
  )

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container" >
        <Breadcrumb>
          <p>
            <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{filterLabel}</span></p></Breadcrumb>
        <Title>
          <h1>{filterLabel}</h1>

        </Title>

        <Table>
          {loading
            ? <div className="loading">
              <TailSpin color="#17406F" />
            </div>
            : <>
              <ListPortfolio portfolio={portfolio} number={number} langue={langue} />
              {(number < itemsFiltred.length) ? <img className="arrow" src="/images/portfolio/Group 20195.svg" alt="arrow" onClick={() => setNumber(number + 9)} />
                : number < portfolio.length && <img className="arrow" src="/images/portfolio/Group 20195.svg" alt="arrow" onClick={() => setNumber(number + 3)} />
              }
            </>
          }

        </Table>
      </div >
    </>)
}



export default withTranslation()(Portfolios)
